import { Tabs, Tag } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { tagsServices } from '../../services/tags-services'
import { tasksService } from '../../services/tasks-services'
import { updateTask } from '../../store/tasks/actions'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'
import { showNotice, showNotify } from '../../utils/helpers/showNotice'
import { TagsContainer } from '../TagsContainer/TagsContainer'
import { SystemTagItem } from '../TagsContainer/types'
import ModalInlog from '../modalInlog/ModalInlog'
import UiInput from '../ui/input/UiInput'
import './TagsDropdown.css'
import { SelectedTag } from './SelectedTag'
import { TagsTypes } from './types'
import { ITagDTO } from '../../app/types/dto/tag.dto'
import { ITask } from '../../app/types/models/task'
import { TagsContext } from './provider'

export const TagsDropdown = ({
    projectId,
    task,
    taskSlug,
    createTag,
}: {
    projectId: number
    task: ITask
    taskSlug?: string
    createTag?: (data: ITagDTO) => void
}) => {
    const dispatch = useAppDispatch()
    const [selectedTags, setSelectedTags] = useState<SystemTagItem[]>(
        task?.tags ?? [],
    )
    const [fetchedTags, setFetchedTags] = useState<SystemTagItem[]>([])
    const [inputVisible, setInputVisible] = useState(false)
    const [inputValue, setInputValue] = useState('')
    const { t } = useTranslation()
    const [collapsed, setCollapsed] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const inputRef = useRef(null)
    const [currentTab, setCurrentTab] = useState<TagsTypes>(TagsTypes.RESENT)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setSelectedTags(task?.tags ?? [])
    }, [task?.tags])

    const taskId = task?.id
    const slug = taskSlug ?? task?.slug

    //загружаю из апи полный список тегов для этого проекта
    const fetchTags = async (value?: string) => {
        try {
            if (projectId) {
                setIsLoading(true)
                const response = await tagsServices.getTags(
                    projectId,
                    value === TagsTypes.ORPHAN,
                )
                setFetchedTags(response?.results)
                if (value !== undefined) {
                    setCurrentTab(value as TagsTypes)
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        } finally {
            setIsLoading(false)
        }
    }

    console.log(' currentTab',  currentTab)

    const deleteTags = async (tagIds: number[]) => {
        try {
            if (fetchedTags) {
                const filteredTags = selectedTags.filter(
                    (item) => !tagIds.includes(item?.id),
                )
                const preparedTags = filteredTags ? filteredTags : []
                const tagsIds = preparedTags.map((item) => item?.id)
                await tasksService.updateTask(
                    { tags: tagsIds },
                    projectId,
                    slug,
                )
                dispatch(
                    updateTask({
                        id: taskId,
                        tags: filteredTags,
                    }),
                )
                setSelectedTags(filteredTags)
                if (tagIds.length === 1) {
                    showNotice('tag-deleted-successfully', t)
                } else {
                    showNotice('all-tags-deleted-successfully', t)
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const showInput = () => {
        setInputVisible(true)
    }

    //инпут для ввода названия кастомного тега
    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const addTagData = useCallback(
        async (data) => {
            if (data && Array.isArray(data)) {
                try {
                    const preparedTagIds = data.map((item) => item?.id)
                    await tasksService.updateTask(
                        { tags: preparedTagIds },
                        projectId,
                        slug,
                    )
                    dispatch(
                        updateTask({
                            id: taskId,
                            tags: data,
                        }),
                    )
                    showNotify(t('tag-added-successfully'))
                } catch (e) {
                    errorsHandler(e, t)
                }
            }
        },
        [slug, taskId, projectId],
    )

    //создаю новый тег в базе и сразу же добавляю его запросом в таску. Для системных тегов
    const createTagData = async (name: string) => {
        try {
            const targetTag = fetchedTags.find((el) => el.name === name)
            if (fetchedTags && !targetTag) {
                const response = await tagsServices.createTag(projectId, {
                    name,
                })
                await addTagData(
                    selectedTags ? [...selectedTags, response] : [response],
                )
                createTag && createTag(response)
                const result = selectedTags
                    ? [...selectedTags, response]
                    : [response]
                setSelectedTags(result)
                dispatch(
                    updateTask({
                        id: taskId,
                        tags: selectedTags
                            ? [...selectedTags, response]
                            : [response],
                    }),
                )
            } else if (fetchedTags && targetTag) {
                const isTagExist = selectedTags.find((tag) => tag.name === name)
                if (isTagExist) {
                    showNotify(t('tag-with-name-already-existed'), {
                        type: 'error',
                    })
                } else {
                    await addTagData(
                        selectedTags
                            ? [...selectedTags, { ...targetTag }]
                            : [targetTag],
                    )
                    const result = selectedTags
                        ? [...selectedTags, { ...targetTag }]
                        : [targetTag]

                    setSelectedTags(result)
                    dispatch(
                        updateTask({
                            id: taskId,
                            tags: result,
                        }),
                    )
                }
            }
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const addTags = (tab: TagsTypes) => async (tags: SystemTagItem[]) => {
        try {
            const newTags: SystemTagItem[] = []
            const existsTags: SystemTagItem[] = []

            tags.forEach((listEl) => {
                const targetTag = fetchedTags.find(
                    (tagEl) => tagEl.name === listEl?.name,
                )
                if (targetTag) {
                    existsTags.push(targetTag)
                } else {
                    newTags.push(listEl)
                }
            })
            if (newTags.length > 0) {
                for (let i = 0; i < newTags.length; i++) {
                    const tag = newTags[i]
                    const res = await tagsServices.createTag(projectId, {
                        name: tag?.name,
                        linked_object_id: tag?.object_id,
                        linked_object_content_type: tag?.systemType,
                        is_systemic:
                            tab === TagsTypes.SYSTEM ? true : undefined,
                    })
                    createTag && createTag(res)
                    existsTags.push(res)
                }
            }

            const tagsIds = new Set([
                // ...selectedTags,
                ...existsTags.map((el) => el.id),
            ])
            await tasksService.updateTask(
                {
                    tags: [
                        ...Array.from(tagsIds),
                        ...selectedTags.map((el) => el?.id),
                    ],
                },
                projectId,
                slug,
            )

            const filteredTagsForSelectedList = existsTags.filter(
                (el) => !selectedTags.find((val) => val?.id === el?.id),
            )
            const result = [...selectedTags, ...filteredTagsForSelectedList]
            setSelectedTags(result)
            dispatch(
                updateTask({
                    id: taskId,
                    tags: result,
                }),
            )
            if (tags.length === 1) {
                showNotify(t('tag-added-successfully'))
            } else {
                showNotify(t('all-tags-added-successfully'))
            }
        } catch (error) {
            errorsHandler(error, t)
        }
    }

    //при кастомном вводе тега при нажатии на enter происходит создание тега и добавления его в список тегов текущей таски
    const handleInputConfirm = async () => {
        if (inputValue && inputValue.length <= 15) {
            await createTagData(inputValue)
        } else if (inputValue && inputValue.length > 15) {
            showNotify(t('max-symblos-length-message', { value: 15 }), {
                type: 'error',
            })
        }
        setInputVisible(false)
        setInputValue('')
    }

    const toggleCollapseHandler = () => {
        setCollapsed(!collapsed)
    }

    const handleChangeTab = async (value: string) => {
        if (value !== TagsTypes.SYSTEM) {
            await fetchTags(value)
        } else {
            setCurrentTab(value)
        }
    }

    const showTabItems = useCallback(() => {
        return [TagsTypes.RESENT, TagsTypes.SYSTEM, TagsTypes.ORPHAN].map(
            (tag) => ({
                label: t(tag).toString(),
                key: tag,
                children: (
                    <TagsContainer
                        key={currentTab}
                        currentTab={currentTab}
                        selectedTags={selectedTags}
                        fetchedTags={fetchedTags}
                        deleteTags={deleteTags}
                        addTags={addTags(currentTab)}
                    />
                ),
            }),
        )
    }, [currentTab, deleteTags, addTags])

    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus()
        }
    }, [])

    useEffect(() => {
        if (
            isOpenModal &&
            taskId !== undefined &&
            [TagsTypes.RESENT, TagsTypes.ORPHAN].includes(currentTab)
        ) {
            fetchTags()
        }
    }, [isOpenModal])

    return (
        <TagsContext.Provider value={{
            isLoading,
            currentTab,
            tags:fetchedTags,
        }}>
            <div>
                <div className="tagsDropdown-top">
                    <div className="tagsDropdown-top-tagSpace">
                        <TweenOneGroup>
                            {selectedTags
                                .filter((f) => f.name !== '')
                                .map((tag) => (
                                    <SelectedTag
                                        tag={tag}
                                        onDelete={deleteTags}
                                    />
                                ))}
                        </TweenOneGroup>
                    </div>
                    <div
                        className="tagsDropdown-inputSpace"
                        onClick={toggleCollapseHandler}
                    >
                        {inputVisible && (
                            <UiInput
                                ref={inputRef}
                                type="text"
                                value={inputValue}
                                onChange={handleInputChange}
                                onBlur={handleInputConfirm}
                                className="tagsDropdown__input"
                                onPressEnter={handleInputConfirm}
                            />
                        )}
                        {!inputVisible && (
                            <Tag onClick={showInput} className="cursor-pointer">
                                <div className="tagsDropdown-add-tag-btn-wrapper">
                                    <span>{t('newTag')}</span>
                                    <PlusIcon circle={false} />
                                </div>
                            </Tag>
                        )}
                        <button
                            className={'cursor-pointer tagsDropdown-btn'}
                            onClick={() => setIsOpenModal(!isOpenModal)}
                        >
                            <PlusIcon circle={false} />
                        </button>
                    </div>
                    <ModalInlog
                        className="scheduler-tags-modal"
                        title={t('tagsList')}
                        open={isOpenModal}
                        onCancel={() => {
                            setIsOpenModal(false)
                        }}
                        footer={null}
                    >
                        <div className="scheduler-tags-modal-inner">
                            <div className="tabs-list">
                                <Tabs
                                    className="scheduler-tags-tabs"
                                    onChange={handleChangeTab}
                                    items={showTabItems()}
                                />
                            </div>
                        </div>
                    </ModalInlog>
                </div>
            </div>
        </TagsContext.Provider>
    )
}
