import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { authService } from '../../services/auth-service'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { setIsLoggedIn, setPasswordResetData } from './actions'
import { setAppStatus } from '../app/actions'
import { ACCESS_TOKEN } from '../../utils/constants'

export interface IAuthState {
    isLoggedIn: boolean
    passwordResetData: any
}

const initialState: IAuthState = {
    isLoggedIn: false,
    passwordResetData: {},
}

const logOut = createAsyncThunk<
    void,
    {
        onSuccess?: () => void
    },
    {
        rejectValue: string
    }
>('auth/LogOut', async ({onSuccess}, thunkAPI) => {
    debugger
    thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
    try {
        await authService.logOut()
        document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
        localStorage.removeItem(ACCESS_TOKEN)
        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        onSuccess && onSuccess()
    } catch (e: any) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

export const asyncActions = { logOut }

export const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsLoggedInAC(state, action: PayloadAction<{ value: boolean }>) {
            state.isLoggedIn = action.payload.value
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setIsLoggedIn, (state, action) => {
            state.isLoggedIn = action.payload.isLoggedIn
        })
        builder.addCase(setPasswordResetData, (state, action) => {
            state.passwordResetData = action.payload.passwordResetData
        })
        builder.addCase(logOut.fulfilled, (state) => {
            state.isLoggedIn = false
        })
    },
})
