import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { ProjectsMenu } from '../../features/projectsMenu/ProjectsMenu'
import { projectsOpenMenuStatus } from '../../store/projects/selectors'
import { routes } from '../../utils/constants'
import LoggingEditSpace from './components/loggingEditSpace/LoggingEditSpace'
import './dataLogging.css'
import { CreateOrganozationProjectBlock } from './components/newProject/NewProject'

const DataLogging = () => {
    const isOpenProjectMenu = useSelector(projectsOpenMenuStatus)

    return (
        <div
            className="Projects"
            style={
                isOpenProjectMenu
                    ? { gridTemplateColumns: '220px 1fr' }
                    : { gridTemplateColumns: '30px 1fr' }
            }
        >
            <ProjectsMenu />
            <Routes>
                <Route
                    path={'new-project'}
                    element={<CreateOrganozationProjectBlock />}
                />
                <Route
                    path={routes.DYNAMIC_PROJECT}
                    element={<LoggingEditSpace />}
                ></Route>
            </Routes>
        </div>
    )
}

export { DataLogging }
