export enum CoreStatuses {
    in_progress = 'in_progress',
    finished = 'finished',
    error = 'error',
}

export enum CorePhotoTypes {
    full_size_sample = 'full_size_sample',
    plug = 'plug',
    cuttings = 'cuttings',
    other = 'other',
}

export enum CorePhotoLightTypes {
    visible = 'visible',
    ultraviolet = 'ultraviolet',
    other = 'other',
}

export enum TargetParameters {
    bio = 'bio',
    bulk_modulus = 'bulk_modulus',
    compressibility = 'compressibility',
    elastic_limit = 'elastic_limit',
    elastic_wave = 'elastic_wave',
    friction_angle = 'friction_angle',
    plastic_deformation = 'plastic_deformation',
    poissons_ratio = 'poissons_ratio',
    shear_modulus = 'shear_modulus',
    ultimate_compressive_strength = 'ultimate_compressive_strength',
    uniaxial_compressive_strength = 'uniaxial_compressive_strength',
    yield_strength = 'yield_strength',
    youngs_modulus = 'youngs_modulus',
    capillary_curve = 'capillary_curve',
    carbonate_content = 'carbonate_content',
    density = 'density',
    oil_displacement = 'oil_displacement',
    permeability = 'permeability',
    porosity = 'porosity',
    saturation = 'saturation',
    thermal_conductivity = 'thermal_conductivity',
    thermal_diffusivity = 'thermal_diffusivity',
    volumetric_heat_capacity = 'volumetric_heat_capacity',
    task = 'task',
}

export enum RoleTypes {
    admin = 'admin',
    member = 'member',
    editor = 'editor',
}

export enum PorosityTypes {
    open = 'open',
    effective = 'effective',
    dynamic = 'dynamic',
    full = 'full',
}

export enum Porosity_saturation_fluid_type {
    open = 'open',
    effective = 'effective',
    dynamic = 'dynamic',
    full = 'full',
}

export enum PriorityTypes {
    low = 'low',
    medium = 'medium',
    important = 'important',
    critical = 'critical',
}

export enum SocialNames {
    microsoft = 'microsoft',
    slack = 'slack',
    yandex = 'yandex',
    google = 'google',
}

export enum PermeabilityComponentTypes {
    parallel = 'parallel',
    perpendicular = 'perpendicular',
}

export enum RequestAction {
    accept = 'accept',
    reject = 'reject',
}

export enum LanguageTypes {
    en = 'en',
    ru = 'ru',
}

export enum CameraPlacementTypes {
    FACE = 'face',
    ENVIRONMENT = 'environment',
}

export enum RoadMapDetalizationTypes {
    QUARTER = 'QUARTER',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}
export enum ProductObjectTypes {
    PAD = 'wellpad',
    WELL = 'well',
    WELLBORE = 'wellbore',
    CORE = 'core',
    METHOD = 'researchmethod',
    EQUIPMENT = 'researchequipment',
    STUDIES = 'studies', //не нашел пока в списке
    STANDARD_STUDIES = 'Standard studies' , //не нашел пока в списке
    MECHANICAL_STUDIES = 'Mechanical studies', //не нашел пока в списке
    THERMAL_STUDIES = 'Thermal studies', //не нашел пока в списке
    CHEMICAL_STUDIES = 'Chemical studies', //не нашел пока в списке

    POROSITY = 'porositymeasurement',
    PERMEABILITY = 'permeabilitymeasurement',
    SATURATION = 'saturationmeasurement',
    OIL_DISPLACEMENT = 'oildisplacementmeasurement',
    YOUNGS_MODULUS = 'youngsmodulusmeasurement',
    POISSONS_RATIO = 'poissonsratiomeasurement',
    ACOUSTIC_VELOCITY = 'acousticvelocitymeasurement',
    COMPRESSIBILITY = 'compressibilitymeasurement',
    SHEAR_MODULUS = 'shearmodulusmeasurement',
    YIELDS_STRENGTH = 'yieldstrengthmeasurement',
    BIO = 'biomeasurement',
    BULK_MODULUS = 'bulkmodulusmeasurement',
    UNIAXIAL_COMPRESSIVE_STRENGTH = 'uniaxialcompressivestrengthmeasurement',
    ULTIMATE_COMPRESSIVE_STRENGTH = 'ultimatecompressivestrengthmeasurement',
    ELASTIC_LIMIT = 'elasticlimitmeasurement',
    THERMAL_CONDUCTIVITY = 'thermalconductivitymeasurement',
    VOLUMETRIC_HEAT_CAPACITY = 'volumetricheatcapacitymeasurement',
    PYROLYSIS = 'pyrolysismeasurement',
}


// Куст - WellPad
// Скважина - Well
// Ствол - Wellbore
// Керн - Core
// Метод - ResearchMethod
// Оборудование - ResearchEquipment
// Пористость - PorosityMeasurement
// Проницаемость - PermeabilityMeasurement
// Насыщение - SaturationMeasurement
// Вытеснение нефти - OilDisplacementMeasurement
// Модуль Юнга - YoungsModulusMeasurement
// Коэф. Пуассона - PoissonsRatioMeasurement
// Скорость акустики - AcousticVelocityMeasurement
// Сжимаемость - CompressibilityMeasurement
// Модуль сдвига - ShearModulusMeasurement
// Предел текучести - YieldStrengthMeasurement
// Био - BioMeasurement
// Модуль объемной упругости - BulkModulusMeasurement
// Прочность на одноосное сжати - UniaxialCompressiveStrengthMeasurement
// Прочность при объемном сжатии - UltimateCompressiveStrengthMeasurement
// Предел упругости - ElasticLimitMeasurement
// Теплопроводность - ThermalConductivityMeasurement
// Температуропроводность - ThermalConductivityMeasurement
// Объемная теплоемкость - VolumetricHeatCapacityMeasurement
// Пиролиз - PyrolysisMeasurement
