import React, {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import { SystemTagItem, SystemTagListItem } from './types'
import { useTranslation } from 'react-i18next'
import ServiceIcon from '../../image_files/icons/ServiceIcon'
import TagsFilter from './TagsFilter'
import TagsSelectBtn from './TagsSelectBtn'
import { TagsTypes } from '../TagsDropdown/types'
import { ITagDTO } from '../../app/types/dto/tag.dto'
import TagsBreadcrumbs from './TagsBreadcrumbs'
import FolderIcon from '../../image_files/icons/FolderIcon'
import { TagsContext } from '../TagsDropdown/provider'
import TagsSkeleton from './TagsSkeleton'

interface OrphanTagsProps {
    tags: SystemTagItem[]
    selectedTags: SystemTagItem[]
    cashList: SystemTagListItem[]

    addTags: (tags: SystemTagItem[]) => void
    deleteTags: (tagsIds: number[]) => void
    handleClickBreadCrumbs: (data: {
        tags: SystemTagItem[]
        cashList: SystemTagListItem[]
    }) => void
    handleClickSystemFolder: (tag: SystemTagItem) => void
}

const TagsList = ({
    selectedTags,
    cashList,
    tags,
    addTags,
    deleteTags,
    handleClickBreadCrumbs,
    handleClickSystemFolder,
}: OrphanTagsProps) => {
    const { t } = useTranslation()
    const { isLoading, currentTab } = useContext(TagsContext)
    const [filteredTags, setFilteredTags] = useState(tags ?? [])

    // const tagsList =
    //     selectedTags && Array.isArray(selectedTags) && selectedTags?.length > 0
    //         ? selectedTags.map((el) => el.name)
    //         : []

    const handleChangeFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setFilteredTags(
            tags.filter((el) => {
                return el.name?.toLocaleLowerCase().indexOf(value) >= 0
            }),
        )
    }

    const handleSelectAll = (tagsListArg: SystemTagItem[]) => {
        addTags && addTags(tagsListArg)
    }

    const handleDeleteAll = (tagsListArg: SystemTagItem[]) => {
        const targetList = selectedTags.filter(
            (tag) =>
                !!tagsListArg?.find(
                    (nestedTag) => nestedTag?.name === tag?.name,
                ),
        )
        deleteTags &&
            targetList?.length > 0 &&
            deleteTags(targetList.map((el) => el?.id))
    }

    const handleClickOnBreadCrumb = (index: number) => {
        if (index !== cashList.length - 1) {
            const targetBreadCrumb = cashList[index]
            if (targetBreadCrumb) {
                const newList = cashList.slice(0, index + 1)
                handleClickBreadCrumbs({
                    cashList: newList,
                    tags: targetBreadCrumb.list,
                })
            }
        }
    }

    const getTagClass = (tag: SystemTagItem) => {
        if (selectedTags && selectedTags.length > 0) {
            const targetTag = selectedTags.find((el) => el.name === tag.name)
            if (tag?.type !== 'folder' && targetTag) {
                return 'tag-element font-14-normal tag-element--selected'
            } else return 'tag-element font-14-normal'
        } else return 'tag-element font-14-normal'
    }

    const toggleTagStatus = (tag: ITagDTO) => {
        const targetTag = selectedTags.find((el) => el.name === tag.name)
        if (targetTag && targetTag?.id !== undefined) {
            deleteTags && deleteTags([targetTag.id])
        } else {
            addTags && addTags([tag])
        }
    }

    const handleSelectTag = (tag: SystemTagItem) => {
        if (tag.type === 'folder') {
            handleClickSystemFolder(tag)
        } else {
            toggleTagStatus(tag)
        }
    }

    const getValidListClass = () => {
        if (currentTab === TagsTypes.SYSTEM) {
            return `system-tags-list ${cashList?.length>1 ? 'system-tags-services' : ''}`
        }
        return 'recent-tags-list'
    }

    useEffect(() => {
        setFilteredTags(tags)
    }, [tags])

    const showValidTagIcon = (tag: SystemTagItem) => {
        if (currentTab === TagsTypes.SYSTEM) {
            return tag.type === 'folder' ? <FolderIcon /> : <ServiceIcon />
        }
        return <ServiceIcon />
    }

    const showFilter = () => {
        if (currentTab === TagsTypes.SYSTEM) {
            const targetService = cashList[cashList.length - 1]?.list?.find(
                (cashItem) => cashItem?.type === 'service',
            )
            if (!targetService) return <></>
        }
        return (
            <TagsFilter
                tags={tags}
                className="mb-8"
                onChange={handleChangeFilter}
            />
        )
    }

    return (
        <>
            {currentTab === TagsTypes.SYSTEM && (
                <TagsBreadcrumbs
                    cashList={cashList}
                    handleClick={handleClickOnBreadCrumb}
                />
            )}
            {!isLoading && showFilter()}
            {isLoading ? (
                <TagsSkeleton />
            ) : (
                <ul className={getValidListClass()}>
                    <div>
                        {filteredTags.length > 0 ? (
                            filteredTags.map((tag) => {
                                return (
                                    <li
                                        key={tag.id}
                                        className={getTagClass(tag)}
                                        onClick={() => handleSelectTag(tag)}
                                    >
                                        {showValidTagIcon(tag)}
                                        <span>{tag?.name}</span>
                                    </li>
                                )
                            })
                        ) : (
                            <div className="font-14-normal">{t('no-data')}</div>
                        )}
                    </div>
                </ul>
            )}
            <TagsSelectBtn
                tags={tags}
                cashListLength={cashList.length}
                selectedTags={selectedTags}
                handleDelete={handleDeleteAll}
                handleSelect={handleSelectAll}
            />
        </>
    )
}

export default TagsList
