import '../../pages/home/home.css'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import './settings.css'
import { IControlNavButton } from './types'
import { useLocation, useNavigate } from 'react-router-dom'
import { routes } from '../../utils/constants'
import SettingsRoutes from './settings-routes'

export const Settings = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const showStylesByParam = (param: string) =>
        location?.pathname?.split('/').at(-1) === param
            ? 'menu-item--selected'
            : ''

    const menuItemClass =
        'p-8 transition1 font-14-normal cursor-pointer br-default'

    const menuItems: IControlNavButton[] = [
        {
            id: 1,
            title: t('profile'),
            onClick: () => navigate(routes.SETTINGS_PROFILE),
            className: `${menuItemClass} ${showStylesByParam(
                routes.SETTINGS_PROFILE,
            )}`,
        },
        {
            id: 2,
            title: t('change-password'),
            onClick: () => navigate(routes.SETTINGS_CHANGE_PASSWORD),
            className: `${menuItemClass} ${showStylesByParam(
                routes.SETTINGS_CHANGE_PASSWORD,
            )}`,
        },
        {
            id: 3,
            title: t('notifications'),
            onClick: () => navigate(routes.SETTINGS_NOTIFICATIONS),
            className: `${menuItemClass} ${showStylesByParam(
                routes.SETTINGS_NOTIFICATIONS,
            )}`,
        },
    ]

    useEffect(() => {
        if (['/settings', '/settings/*'].includes(location?.pathname)) {
            navigate('/settings/profile')
        }
    }, [location])

    return (
        <div className="settings">
            <ul className="column-flex">
                {menuItems.map((item, i) => (
                    <li
                        key={i}
                        className={item.className}
                        onClick={item.onClick}
                    >
                        {item.title}
                    </li>
                ))}
            </ul>
            <div className='settings__route'>
                <SettingsRoutes />
            </div>
        </div>
    )
}
