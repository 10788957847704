import { Tag } from 'antd'
import { SystemTagItem } from '../TagsContainer/types'
import React from 'react'
import UiPopover from '../ui/popover/UiPopover'
import UiPopoverContent from '../ui/popover/UiPopoverContent'
import { useTranslation } from 'react-i18next'

interface IProps {
    tag: SystemTagItem
    onDelete: (ids: number[]) => void
}

export const SelectedTag = ({ tag, onDelete }: IProps) => {
    const { t } = useTranslation()

    const handleDelete = (e) => {
        e.preventDefault()
        onDelete([tag?.id])
    }

    const getPopoverMessage = () => {
        if (tag?.is_orphan) return t('orphan')
        if (tag?.is_systemic) return t('sytsem')
        return t('custom')
    }

    const tagElem = (
        <UiPopover
            key={tag?.id}
            color="black"
            content={
                <UiPopoverContent
                    title={getPopoverMessage()}
                />
            }
        >
            <Tag
                closable
                className={`selected-tag ${
                    tag?.is_orphan ? 'selected-tag--archived' : ''
                }`}
                onClose={handleDelete}
            >
                <span>{tag?.name}</span>
            </Tag>
        </UiPopover>
    )
    return <span className="selected-tag-wrapper" key={tag?.id}>{tagElem}</span>
}
