import { TFunction } from 'i18next'
import { entitiesServices } from '../../../services/entities-services'
import { projectsService } from '../../../services/projects-service.'
import { wellBoreService } from '../../../services/wellBore-service'
import { wellsService } from '../../../services/wells-service'
import { errorsHandler } from '../../../utils/helpers/errors/errors-hendler'
import { SystemTagItem } from '../types'
import { ProductObjectTypes } from '../../../app/types/enums'

export const getCurrentSystemTags = async (
    el: SystemTagItem,
    projectId: number,
    t: TFunction<'translation', undefined, 'translation'>,
) => {
    const type = el.systemType
    try {
        switch (type) {
            case ProductObjectTypes.PAD: {
                const res = await projectsService.getPads(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.WELL: {
                const res = await wellsService.getWells(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.WELLBORE: {
                const res = await wellBoreService.getWellBores(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.CORE: {
                const res = await projectsService.getCores(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.METHOD: {
                const res = await entitiesServices.getMethod(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.EQUIPMENT: {
                const res = await entitiesServices.getEquipment(projectId)
                return getPreparedTagsFromResponse(res, el)
            }
            case ProductObjectTypes.STUDIES: {
                const list = [
                    {
                        name: t('standard-studies'),
                        systemType: ProductObjectTypes.STANDARD_STUDIES,
                        type: 'folder',
                    },
                    {
                        name: t('mechanical-studies'),
                        systemType: ProductObjectTypes.MECHANICAL_STUDIES,
                        type: 'folder',
                    },
                    {
                        name: t('thermal-studies'),
                        systemType: ProductObjectTypes.THERMAL_STUDIES,
                        type: 'folder',
                    },
                    {
                        name: t('chemical-studies'),
                        systemType: ProductObjectTypes.CHEMICAL_STUDIES,
                        type: 'folder',
                    },
                ]
                return getPreparedTagsFromResponse(list, el)
            }
            case ProductObjectTypes.STANDARD_STUDIES: {
                const list = [
                    {
                        name: t('porosity'),
                        systemType: ProductObjectTypes.POROSITY,
                        type: 'service',
                    },
                    {
                        name: t('permeability'),
                        systemType: ProductObjectTypes.PERMEABILITY,
                        type: 'service',
                    },
                    {
                        name: t('saturation'),
                        systemType: ProductObjectTypes.SATURATION,
                        type: 'service',
                    },
                    {
                        name: t('oil-displasment'),
                        systemType: ProductObjectTypes.OIL_DISPLACEMENT,
                        type: 'service',
                    },
                ]
                return getPreparedTagsFromResponse(list, el)
            }
            case ProductObjectTypes.MECHANICAL_STUDIES: {
                const list = [
                    {
                        name: t('young-modulus'),
                        systemType: ProductObjectTypes.YOUNGS_MODULUS,
                        type: 'service',
                    },
                    {
                        name: t('poisson-ratio'),
                        systemType: ProductObjectTypes.POISSONS_RATIO,
                        type: 'service',
                    },
                    {
                        name: t('acoustic-velocity'),
                        systemType: ProductObjectTypes.ACOUSTIC_VELOCITY,
                        type: 'service',
                    },
                    {
                        name: t('compressibility'),
                        systemType: ProductObjectTypes.COMPRESSIBILITY,
                        type: 'service',
                    },
                    {
                        name: t('shear-modulus'),
                        systemType: ProductObjectTypes.SHEAR_MODULUS,
                        type: 'service',
                    },
                    {
                        name: t('yield-strength'),
                        systemType: ProductObjectTypes.YIELDS_STRENGTH,
                        type: 'service',
                    },
                    {
                        name: t('bio'),
                        systemType: ProductObjectTypes.BIO,
                        type: 'service',
                    },
                    {
                        name: t('bulk-modulus'),
                        systemType: ProductObjectTypes.BULK_MODULUS,
                        type: 'service',
                    },
                    {
                        name: t('compressive-strength'),
                        systemType: ProductObjectTypes.COMPRESSIBILITY,
                        type: 'service',
                    },
                    {
                        name: t(
                            'ultimate-compressive-strength',
                        ),
                        systemType: ProductObjectTypes.ULTIMATE_COMPRESSIVE_STRENGTH,
                        type: 'service',
                    },
                    {
                        name: t('elastic-limit'),
                        systemType: ProductObjectTypes.ELASTIC_LIMIT,
                        type: 'service',
                    },
                ]
                return  getPreparedTagsFromResponse(list, el)
            }
            case ProductObjectTypes.THERMAL_STUDIES: {
                const list = [
                    {
                        name: t('conductivity'),
                        systemType: ProductObjectTypes.THERMAL_CONDUCTIVITY,
                        type: 'service',
                    },
                    {
                        name: t('diffusivity'),
                        systemType: ProductObjectTypes.THERMAL_CONDUCTIVITY,
                        type: 'service',
                    },
                    {
                        name: t('capacity'),
                        systemType: ProductObjectTypes.VOLUMETRIC_HEAT_CAPACITY,
                        type: 'service',
                    },
                ]
                return  getPreparedTagsFromResponse(list, el)
            }
            case ProductObjectTypes.CHEMICAL_STUDIES: {
                const list = [
                    {
                        name: t('pyrolysis'),
                        systemType: ProductObjectTypes.PYROLYSIS,
                        type: 'service',
                    },
                ]
                return getPreparedTagsFromResponse(list, el)
            }
            default:
                return {cashListItem:undefined,tags:[]}
        }
    } catch (e) {
        errorsHandler(e, t)
    }
}

const getPreparedTagsFromResponse = (res: any, elArg: SystemTagItem) => {
    const type = elArg.systemType
    const tags: SystemTagItem[] = res?.map((el, i) => {
        return {
            id: i,
            name: el.name,
            object_id:el?.id,
            type:
                type === ProductObjectTypes.STUDIES || el?.type === 'folder'
                    ? 'folder'
                    : 'service',
            systemType: el?.systemType ? el?.systemType : type,
        }
    })
    const cashListItem = {
        name: elArg.name,
        list: tags,
    }
    return {
        cashListItem,
        tags,
    }
}
