import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SimpleBar from 'simplebar-react'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { ToggleMenuIcon } from '../../image_files/icons/ToggleMenuIcon'
import { projectsActions, projectsSelectors } from '../../store/projects'
import ProjectItem from './components/projectItem/ProjectItem'
import './projectsMenu.css'
import { routes } from '../../utils/constants'

const ProjectsMenu = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { selectProjects, projectsOpenMenuStatus } = projectsSelectors
    const projects = useSelector(selectProjects)
    const isOpenProjectMenu = useSelector(projectsOpenMenuStatus)

    const { setIsOpenProjectsMenuStatus } = projectsActions

    const addProjectHandler = () => {
        navigate(routes.NEW_PROJECT)
    }

    const setTextVisibility = () => {
        if (isOpenProjectMenu) {
            return { visibility: 'visible' } as CSSProperties
        } else {
            return { visibility: 'hidden' } as CSSProperties
        }
    }

    return (
        <div
            className={`projectsMenu pt-5 font-14-normal 
            ${isOpenProjectMenu ? 'projectsMenu__open' : ''}`}
        >
            <div
                className={`flex-c-sb h-30  
                ${isOpenProjectMenu ? 'projectsMenu__top-open' : ''}`}
            >
                <div className={'row-flex-5'}>
                    <ToggleMenuIcon
                        className={'transition3 cursorPointer'}
                        onClick={() =>
                            dispatch(
                                setIsOpenProjectsMenuStatus(!isOpenProjectMenu),
                            )
                        }
                        isMenuOpen={isOpenProjectMenu}
                        style={
                            isOpenProjectMenu
                                ? { transform: 'rotate(0deg)' }
                                : { transform: 'rotate(-180deg)' }
                        }
                    />
                    <span className={'transition1'} style={setTextVisibility()}>
                        {`${t('projects')}`}
                    </span>
                </div>
                {isOpenProjectMenu && (
                    <div className="add-entity-icon-wrap">
                        <PlusIcon
                            circle={false}
                            className={'cursor-pointer'}
                            onClick={addProjectHandler}
                        />
                    </div>
                )}
            </div>
            <div className="mt-8">
                <SimpleBar
                    className="projectsMenu__projectsList-simpleBar"
                    style={{
                        display: isOpenProjectMenu ? 'block' : 'none',
                    }}
                >
                    <div className="projectsMenu__projectsListInner">
                        {projects
                            ? projects?.map((project) => (
                                  <ProjectItem
                                      key={project?.id}
                                      project={project}
                                      type="project"
                                  >
                                      {project?.pads?.length > 0 &&
                                          project?.pads.map((itemPad) =>
                                              itemPad ? (
                                                  <ProjectItem
                                                      type="pad"
                                                      pad={itemPad}
                                                      key={itemPad.id}
                                                      project={project}
                                                  >
                                                      {
                                                          //мэпинг скважин
                                                          itemPad.wells &&
                                                              itemPad.wells
                                                                  .length > 0 &&
                                                              itemPad.wells.map(
                                                                  (itemWell) =>
                                                                      itemWell ? (
                                                                          <ProjectItem
                                                                              type="well"
                                                                              pad={
                                                                                  itemPad
                                                                              }
                                                                              well={
                                                                                  itemWell
                                                                              }
                                                                              key={
                                                                                  itemWell.id
                                                                              }
                                                                              project={
                                                                                  project
                                                                              }
                                                                          >
                                                                              <ProjectItem
                                                                                  key={
                                                                                      itemWell.id
                                                                                  }
                                                                                  type="wellCore"
                                                                                  pad={
                                                                                      itemPad
                                                                                  }
                                                                                  well={
                                                                                      itemWell
                                                                                  }
                                                                                  project={
                                                                                      project
                                                                                  }
                                                                              />
                                                                              {
                                                                                  // мэпинг стволов
                                                                                  itemWell
                                                                                      ?.wellBores
                                                                                      ?.length >
                                                                                      0 &&
                                                                                      itemWell?.wellBores?.map(
                                                                                          (
                                                                                              itemWellBore,
                                                                                          ) =>
                                                                                              itemWellBore &&
                                                                                              itemWellBore.well ===
                                                                                                  itemWell.id ? (
                                                                                                  <ProjectItem
                                                                                                      key={
                                                                                                          itemWellBore?.id
                                                                                                      }
                                                                                                      type="wellbore"
                                                                                                      pad={
                                                                                                          itemPad
                                                                                                      }
                                                                                                      well={
                                                                                                          itemWell
                                                                                                      }
                                                                                                      wellbore={
                                                                                                          itemWellBore
                                                                                                      }
                                                                                                      project={
                                                                                                          project
                                                                                                      }
                                                                                                  >
                                                                                                      {
                                                                                                          // мэпинг стволовых кернов
                                                                                                          itemWellBore &&
                                                                                                              itemWellBore?.cores &&
                                                                                                              itemWellBore
                                                                                                                  .cores
                                                                                                                  .length >
                                                                                                                  0 &&
                                                                                                              itemWellBore?.cores.map(
                                                                                                                  (
                                                                                                                      itemCore,
                                                                                                                  ) =>
                                                                                                                      itemCore.wellbore ===
                                                                                                                      itemWellBore.id ? (
                                                                                                                          <ProjectItem
                                                                                                                              key={
                                                                                                                                  itemCore?.id
                                                                                                                              }
                                                                                                                              type="wellboreCore"
                                                                                                                              pad={
                                                                                                                                  itemPad
                                                                                                                              }
                                                                                                                              well={
                                                                                                                                  itemWell
                                                                                                                              }
                                                                                                                              wellbore={
                                                                                                                                  itemWellBore
                                                                                                                              }
                                                                                                                              project={
                                                                                                                                  project
                                                                                                                              }
                                                                                                                          />
                                                                                                                      ) : (
                                                                                                                          ''
                                                                                                                      ),
                                                                                                              )
                                                                                                      }
                                                                                                  </ProjectItem>
                                                                                              ) : (
                                                                                                  ''
                                                                                              ),
                                                                                      )
                                                                              }
                                                                          </ProjectItem>
                                                                      ) : null,
                                                              )
                                                      }
                                                  </ProjectItem> //itm
                                              ) : (
                                                  ''
                                              ),
                                          )}
                                  </ProjectItem>
                              ))
                            : ''}
                    </div>
                </SimpleBar>
            </div>
        </div>
    )
}

export { ProjectsMenu }
