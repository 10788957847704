import React, { useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import SchedulerMenu from '../../features/scheduler-menu/SchedulerMenu'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { setSchedulerPage } from '../../store/app/actions'
import { selectSchedulerPage } from '../../store/app/selectors'
import { setProject } from '../../store/currentProject/actions'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { organizationActions } from '../../store/organization'
import { projectsActions } from '../../store/projects'
import { setCurrentOrganization } from '../../store/projects/actions'
import {
    selectCurrentOrganization,
    selectProjects,
} from '../../store/projects/selectors'
import SchedulerRoutes from './components/scheduler-routes'
import './scheduler.css'
import { SchedulerPagesType } from './types'
import { getUrlChunks } from '../../utils/urlHelpers'

export const Scheduler = () => {
    const dispatch = useAppDispatch()
    const schedulerPage = useSelector(selectSchedulerPage)
    const projects = useSelector(selectProjects)
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)

    const getDataAfterReload = () => {
        if (!currentOrganization && projects.length === 0) {
            const urlChunks = getUrlChunks()
            dispatch(
                organizationActions.fetchOrganizations({
                    onSuccess: (res) => {
                        if (res && res.length > 0) {
                            if (urlChunks[1] && Number(urlChunks[1])) {
                                const targetOrganization = res.find(
                                    (el) => el?.id === Number(urlChunks[1]),
                                )
                                if (targetOrganization) {
                                    dispatch(
                                        setCurrentOrganization(
                                            targetOrganization?.id,
                                        ),
                                    )
                                    dispatch(
                                        projectsActions.getProjects({
                                            params: {
                                                organization:
                                                    targetOrganization?.id,
                                            },
                                            onSuccess: (res) => {
                                                if (
                                                    urlChunks[2] &&
                                                    Number(urlChunks[2])
                                                ) {
                                                    const targetProject =
                                                        res.find(
                                                            (el) =>
                                                                el?.id ===
                                                                Number(
                                                                    urlChunks[2],
                                                                ),
                                                        )
                                                    if (targetProject) {
                                                        dispatch(
                                                            setProject({
                                                                project:
                                                                    targetProject,
                                                            }),
                                                        )

                                                        if (urlChunks[3]) {
                                                            dispatch(
                                                                setSchedulerPage(
                                                                    urlChunks[3] as SchedulerPagesType,
                                                                ),
                                                            )
                                                        }
                                                    }
                                                }
                                            },
                                        }),
                                    )
                                }
                            } else {
                                dispatch(setCurrentOrganization(res[0]?.id))
                                dispatch(
                                    projectsActions.getProjects({
                                        params: {
                                            organization: res[0]?.id,
                                        },
                                        onSuccess: (res) => {
                                            if (
                                                urlChunks[2] &&
                                                Number(urlChunks[2])
                                            ) {
                                                const targetProject = res.find(
                                                    (el) =>
                                                        el?.id ===
                                                        Number(urlChunks[2]),
                                                )
                                                if (targetProject) {
                                                    dispatch(
                                                        setProject({
                                                            project:
                                                                targetProject,
                                                        }),
                                                    )

                                                    if (urlChunks[3]) {
                                                        dispatch(
                                                            setSchedulerPage(
                                                                urlChunks[3] as SchedulerPagesType,
                                                            ),
                                                        )
                                                    }
                                                }
                                            }
                                        },
                                    }),
                                )
                            }
                        }
                    },
                }),
            )
        }
    }

    useLayoutEffect(() => {
        getDataAfterReload()
    }, [currentOrganization])

    useEffect(() => {
        return () => {
            if (schedulerPage !== SchedulerPagesType.tasks) {
                dispatch(setSchedulerPage(SchedulerPagesType.tasks))
            }
        }
    }, [])

    return (
        <div className="scheduler">
            <div className={'scheduler__inner'}>
                <SchedulerMenu />
                <SchedulerRoutes />
            </div>
        </div>
    )
}
