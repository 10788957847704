import { createContext } from "react";
import { SystemTagItem } from "../TagsContainer/types";
import { TagsTypes } from "./types";

export const TagsContext = createContext<{
    isLoading:boolean
    tags:SystemTagItem[],
    currentTab:TagsTypes
}>({
    isLoading:false,
    tags:[],
    currentTab:TagsTypes.RESENT
})