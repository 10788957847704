import { Skeleton } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ITask } from '../../../../app/types/models/task'
import { UITextEditor } from '../../../../shared/text-editor/TextEditor'
import { UiAccordion } from '../../../../shared/ui/accordion/UiAccordion'

interface TaskTextEditorProps {
    task: ITask
    isLoading:boolean
    handleChange: (data: any) => void
}

const TaskTextEditor = ({ task,isLoading, handleChange }: TaskTextEditorProps) => {
    const { t } = useTranslation()
    return (
        <UiAccordion title={t('description')} activeStatus>
            {isLoading ? (
                <Skeleton.Input
                    active
                    className="text-editor-skeleton"
                />
            ) : (
                <UITextEditor
                    key={task?.id}
                    initialText={task?.description}
                    handleChange={handleChange}
                />
            )}
        </UiAccordion>
    )
}

export default TaskTextEditor
