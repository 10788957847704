import { t } from 'i18next'
import {toast} from 'react-toastify'
const errorStatuses = [
    405,500,404
]

const errorCodes = [
    'ERR_BAD_REQUEST',
    'ERR_BAD_RESPONSE',
]

export const errorsHandler = (err: any, f?: Function) => {
    let targetFn = f || t
    if(errorCodes.includes(err.code) &&  errorStatuses.includes(err.request.status) && !(err?.response?.data?.hasOwnProperty('detail'))){
        toast.error(targetFn('something-went-wrong'), { position: 'top-center' })
    }else{
        if (err?.response?.data) {
            Object.values(err.response.data).forEach((item:any,i) => {
                if (Array.isArray(item) && item.length > 0) {
                    console.log(item)
                    item.forEach((v) => {
                        toast.error(`${Object.keys(err.response.data)[i]}: - ${v}`, { position: 'top-center' })
                    })
                } else {
                    if (item && typeof item === 'string') {
                        toast.error(item, { position: 'top-center' })
                    }else{
                        for(const prop in item){
                            console.log(item[prop])
                            if(item[prop]){
                                item[prop].forEach(v=>{
                                    toast.error(v, { position: 'top-center' })
                                })
                            }
                        }
                    }
                }

            })
        }
        if(err?.response?.data?.messages){
            Object.values(err.response?.data?.messages).map((item) => {
                if (Array.isArray(item) && item.length > 0) {
                    item?.forEach((v) => {
                        if(v==='Network Error'){
                            toast.error(targetFn('something-went-wrong'), { position: 'top-center' })
                        }
                        toast.error(v, { position: 'top-center' })
                    })
                } else {
                    if (item && typeof item === 'string') {
                        toast.error(item, { position: 'top-center' })
                    }
                }
            })
        }
        if(err?.message === 'Network Error'){
            toast.error(targetFn('something-went-wrong'), { position: 'top-center' })
        }
    }
    console.log(err)
}
