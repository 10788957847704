import React from 'react'
import { SystemTagListItem } from './types'
import ArrowShortLeft from '../../image_files/icons/ArrowShortLeft'

interface TagsBreadcrumbsProps {
    cashList: SystemTagListItem[]
    handleClick:(index:number)=>void
}

const TagsBreadcrumbs = ({cashList,handleClick}: TagsBreadcrumbsProps) => {
    return (
        <div className="system-tags-breadcrumbs">
            <div></div>
            {cashList.map((el, index) => (
                <div
                    key={el.name + index}
                    className={`font-14-normal system-tags-breadcrumb`}
                    onClick={() => handleClick(index)}
                >
                    {el.name}
                    {index < cashList.length - 1 && (
                        <ArrowShortLeft height={20} />
                    )}
                </div>
            ))}
        </div>
    )
}

export default TagsBreadcrumbs
