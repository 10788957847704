import React, { useContext } from 'react'
import { TagsTypes } from '../TagsDropdown/types'
import { SystemTagItem } from './types'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { TagsContext } from '../TagsDropdown/provider'

interface TagsSelectBtnProps {
    tags: SystemTagItem[]
    cashListLength: number
    selectedTags: SystemTagItem[]

    handleSelect?: (tags: SystemTagItem[]) => void
    handleDelete?: (tags: SystemTagItem[]) => void
}

const TagsSelectBtn = ({
    tags,
    cashListLength,
    selectedTags,
    handleSelect,
    handleDelete,
}: TagsSelectBtnProps) => {
    const { t } = useTranslation()
    const { currentTab } = useContext(TagsContext)
    const filteredTags = tags.filter(
        (el) =>
            el?.type !== 'folder' &&
            el?.name &&
            !selectedTags.find((val) => val.name === el?.name),
    )

    if (currentTab === TagsTypes.RESENT || cashListLength > 1) {
        return (
            <div className="system-tags-list-buttons-list">
                <Button
                    className="tag-btn--select"
                    disabled={filteredTags.length === 0}
                    onClick={() => handleSelect(filteredTags)}
                >
                    {t('select-All')}
                </Button>
                <Button
                    className="tag-btn--delete"
                    disabled={filteredTags.length === tags.length}
                    onClick={() => handleDelete(tags)}
                >
                    {t('delete-All')}
                </Button>
            </div>
        )
    } else {
        return <></>
    }
}

export default TagsSelectBtn
