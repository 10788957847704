import { Skeleton } from "antd"
import React from "react"

interface TagsSkeletonProps {}

const TagsSkeleton = ({}: TagsSkeletonProps) => {
    return (
        <div className="tags-skeleton">
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
            <Skeleton.Input
                active
                className="tags-skeleton__item"
            />
        </div>
    )
}

export default TagsSkeleton