import { FC, default as React, default as react, ReactNode, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonItem } from '../../app/types/i-button'
import { ITask } from '../../app/types/models/task'
import { TasksTabTypes } from '../../features/tasks/types'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { DeleteIcon } from '../../image_files/icons/DeleteIcon'
import { TempateIcon } from '../../image_files/icons/TemplateIcon'
import { getTaskSlugFromUrl } from '../../services/get-task-slug-from-url'
import ModalInlog from '../../shared/modalInlog/ModalInlog'
import PopoverMenu from '../../shared/popoverMenu/PopoverMenu'
import { stopEvent } from '../../shared/stopEvent'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { tasksActions } from '../../store/tasks'
import { findColorByParam } from '../../utils/findColorByParam'
import { getCorrectDateFormat } from '../../utils/getCorrectDateFormat'
import { showNotify } from '../../utils/helpers/showNotice'
import EditTaskModal from '../editTaskModal/EditTaskModal'
import './task.css'
import { errorsHandler } from '../../utils/helpers/errors/errors-hendler'

interface IProps {
    task: ITask
    type: TasksTabTypes
    onClick: (value: string) => void
}

const initialModalData: IModalData = {
    title: '',
    isOpen: false,
    text: '',
    type: 'delete',
    data: {},
}

interface IModalData {
    title: string
    titleIcon?: ReactNode
    isOpen: boolean
    text: string
    type: 'delete' | 'edit'
    saveBtnTitle?: string
    data?: any
    onSave?: () => void
}

export const Task: FC<IProps> = react.memo(({ task, type, onClick }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentProject = useSelector(selectCurrentProject)
    const [closeMenuTrigger, setCloseMenuTrigger] = useState(0)
    const [modalsData, setModalsData] = useState(initialModalData)

    const deleteITem = () => {
        dispatch(
            tasksActions.removeTask({
                projectId: currentProject?.id,
                taskSlug: task?.slug,
                onSuccess: () => {
                    setCloseMenuTrigger((prev) => prev + 1)
                    setModalsData(initialModalData)
                    const urlChunks = location.pathname
                        .split('/')
                        .filter((el) => el !== '')
                    if (urlChunks.length > 5) {
                        const path = urlChunks
                            .slice(0, urlChunks.length - 1)
                            .join('/')
                        navigate(path)
                    }
                    navigate(location.pathname, { replace: true })
                },
            }),
        )
    }

    const createTemplate = () => {
        dispatch(
            tasksActions.createTemplate({
                projectId: currentProject?.id,
                taskId:task.id,
                onSuccess: (res) => {
                    showNotify(t('template-added-successfully'))
                    dispatch(
                        tasksActions.getCurrentTask({
                            slug: res?.slug,
                            isHideLoading:true,
                            onSuccess: (taskDetail) => {
                                if (taskDetail) {
                                    setModalsData({
                                        isOpen: true,
                                        text: '',
                                        title: '',
                                        onSave: () => undefined,
                                        data: taskDetail,
                                        type: 'edit',
                                    })
                                    setCloseMenuTrigger((prev) => prev + 1)
                                }
                            },
                            onError:(e)=>{
                                errorsHandler(e,t)
                            }
                        }),
                    )
                },
            }),
        )
    }

    const showValidItems = () => {
        if (type === TasksTabTypes.TASKS) {
            return [
                {
                    id: 0,
                    title: t('create-template'),
                    icon: <TempateIcon />,
                    onClick: createTemplate,
                },
                {
                    id: 1,
                    title: t('delete-task'),
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setModalsData({
                            isOpen: true,
                            text: t('you-realy-want-do-delete-this-task'),
                            titleIcon: <DeleteIcon width={18} height={18} />,
                            title: t('task-deleting'),
                            onSave: deleteITem,
                            type: 'delete',
                        })
                        setCloseMenuTrigger((prev) => prev + 1)
                    },
                },
            ]
        } else {
            return [
                {
                    id: 0,
                    title: t('delete-template'),
                    icon: <DeleteIcon />,
                    onClick: () => {
                        setModalsData({
                            isOpen: true,
                            text: t('you-realy-want-do-delete-this-template'),
                            titleIcon: <DeleteIcon width={18} height={18} />,
                            title: t('template-editing'),
                            onSave: deleteITem,
                            type: 'delete',
                        })
                        setCloseMenuTrigger((prev) => prev + 1)
                    },
                },
            ]
        }
    }

    const modalBtnList: ButtonItem[] = [
        {
            id: 1,
            titleBtn: t('cancel'),
            className: 'font-14-normal-imp',
            handleBtn: () => setModalsData(initialModalData),
        },
        {
            id: 2,
            type: 'primary',
            titleBtn: modalsData?.saveBtnTitle || t('delete'),
            handleBtn: modalsData?.onSave,
        },
    ]

    const handleClick = (e) => {
        stopEvent(e)
        if (!modalsData?.isOpen) {
            onClick(task?.slug)
        }
    }

    return (
        <div
            className={`br-default transition-2 cursor-pointer pos-relative ${
                type === TasksTabTypes.TASKS
                    ? 'scheduler-task'
                    : 'scheduler-template'
            }`}
            onClick={handleClick}
            style={
                getTaskSlugFromUrl(location.pathname) === task.slug
                    ? { backgroundColor: 'rgba(148, 215, 219, 0.3)' }
                    : {}
            }
        >
            {type === TasksTabTypes.TASKS && (
                <div
                    className="scheduler-task__priority"
                    style={findColorByParam(task?.priority)}
                ></div>
            )}

            <div className="font-14-normal">
                <div className="row-flex-sb">
                    <div>{task?.slug}</div>
                    <div>
                        {getCorrectDateFormat({ date: task?.created_at })}
                    </div>
                </div>

                <div className={'dotted-text-210'}>{task?.name}</div>

                {modalsData.type === 'delete' &&
                    createPortal(
                        <ModalInlog
                            width={400}
                            open={modalsData.isOpen}
                            title={modalsData.title}
                            iconTitle={modalsData?.titleIcon}
                            listButton={modalBtnList}
                            onCancel={() => setModalsData(initialModalData)}
                        >
                            <div className="font-14-normal roadmap-item-modal-inner">
                                {modalsData?.text}
                            </div>
                        </ModalInlog>,
                        document.body,
                    )}
                {modalsData.type === 'edit' && (
                    <EditTaskModal
                        isOpen={modalsData.isOpen}
                        title={modalsData.title}
                        type="template"
                        projectId={currentProject?.id}
                        data={{
                            ...modalsData.data,
                            id: modalsData.data?.id,
                        }}
                        closeModal={() => setModalsData(initialModalData)}
                    />
                )}
            </div>
            <div>
                <PopoverMenu
                    items={showValidItems()}
                    closeTrigger={closeMenuTrigger}
                    className="scheduler-task-menu"
                />
            </div>
        </div>
    )
})
