import React, { ChangeEvent, SetStateAction, useEffect, useState } from 'react'
import moment from 'moment'
import en_EN from 'antd/es/date-picker/locale/en_US'
import ru_RU from 'antd/es/date-picker/locale/ru_RU'
import UiInput from '../../../../shared/ui/input/UiInput'
import { useTranslation } from 'react-i18next'
import { DatePicker } from 'antd'
import { UpIcon } from '../../../../image_files/icons/UpIcon'
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../../../app/constants'
import { ITasksFilterParams } from '../../../../app/types/models/task'
import useDebounce from '../../../../hooks/useDebounce'
import './TasksFilter.css';

const TasksFilter = ({
    filterParams,
    isDisabledSort,
    setFilterParams,
}: {
    filterParams: ITasksFilterParams
    isDisabledSort?: boolean
    setFilterParams: (data: SetStateAction<ITasksFilterParams>) => void
}) => {
    const { t, i18n } = useTranslation()
    const [value, setValue] = useState(filterParams?.name__icontains)
    const debouncedName = useDebounce(value, 500)

    const handleChangeDate = (value: any[]) => {
        if (Array.isArray(value)) {
            const startValue = moment(new Date(value[0])).format(
                DATE_FORMAT_TO_SERVER,
            )
            const endValue = moment(new Date(value[1])).format(
                DATE_FORMAT_TO_SERVER,
            )
            setFilterParams({
                ...filterParams,
                created_at__range: `${startValue},${endValue}`,
            })
        } else {
            setFilterParams({ ...filterParams, created_at__range: `` })
        }
    }

    const handleSort = () => {
        if (filterParams?.ordering === 'created_at') {
            setFilterParams({ ...filterParams, ordering: '-created_at' })
        } else {
            setFilterParams({ ...filterParams, ordering: 'created_at' })
        }
    }

    useEffect(() => {
        setFilterParams({ ...filterParams, name__icontains: value })
    }, [debouncedName])

    return (
        <div>
            <div className={'scheduler__tasks-filter-search'}>
                <UiInput
                    placeholder={t('input-task-name')}
                    value={value || ''}
                    onChange={(e) => {
                        setValue(e.target.value || '')
                    }}
                />
            </div>
            <div className={'mt-10 tasks-filter__sort'}>
                <DatePicker.RangePicker
                    allowClear
                    format={DATE_FORMAT}
                    className="datePicker-item"
                    disabled={isDisabledSort}
                    locale={i18n.language === 'ru' ? ru_RU : en_EN}
                    onChange={handleChangeDate}
                />
                {!isDisabledSort ? (
                    <div
                        className="cursor-pointer font-14-normal scheduler__tasks-sort-age"
                        onClick={handleSort}
                    >
                        <span>
                            {filterParams.ordering === 'created_at'
                                ? t('new-plural').toString()
                                : t('old-plural').toString()}
                        </span>
                        <UpIcon
                            className={
                                filterParams.ordering === '-created_at'
                                    ? 'scheduler__tasks-sort-age--down'
                                    : ''
                            }
                        />
                    </div>
                ) : <div/>}
            </div>
        </div>
    )
}

export default TasksFilter
