import React from 'react'
import { ClockIcon } from '../../image_files/icons/ClockIcon'
import { useTranslation } from 'react-i18next'
import enUS from 'antd/es/locale/en_US'
import ruRU from 'antd/es/locale/ru_RU'
import { ConfigProvider, DatePicker } from 'antd'
import { DATE_FORMAT, DATE_FORMAT_TO_SERVER } from '../../app/constants'
import moment, { Moment } from 'moment'
import { TasksTabTypes } from '../../features/tasks/types'
import { ITask } from '../../app/types/models/task'
import './TaskDateBlock.css';

interface TaskDetailsDateBlockProps {
    type: TasksTabTypes
    task: ITask
    isShowIcon?: boolean
    onChange: (data: { due_date_start?: string; due_date_end?: string }) => void
}

const TaskDateBlock = ({
    type,
    task,
    isShowIcon = true,
    onChange,
}: TaskDetailsDateBlockProps) => {
    const { t, i18n } = useTranslation()
    const locale = i18n.language === 'ru' ? ruRU : enUS

    const handleChangeRate = (value: any | any[]) => {
        if (value) {
            if (Array.isArray(value)) {
                const due_date_start = moment(new Date(value[0])).format(
                    DATE_FORMAT_TO_SERVER,
                )
                const due_date_end = moment(new Date(value[1])).format(
                    DATE_FORMAT_TO_SERVER,
                )
                onChange({ due_date_start, due_date_end })
            } else {
                const due_date_end = moment(new Date(value)).format(
                    DATE_FORMAT_TO_SERVER,
                )
                onChange({ due_date_end })
            }
        }
    }

    const disableDate = (current: Moment): boolean => {
        if (type === TasksTabTypes.TEMPLATES) {
            return current && current < moment().startOf('day')
        } else return false
    }

    const getTodayDate = () => {
        const today = new Date()
        const day = String(today.getDate()).padStart(2, '0')
        const month = String(today.getMonth() + 1).padStart(2, '0')
        const year = today.getFullYear()

        return `${day}-${month}-${year}`
    }

    const getDateValue = (): any => {
        if (type === TasksTabTypes.TASKS) {
            return [moment(task?.due_date_start), moment(task?.due_date_end)]
        } else {
            if (task?.days_to_complete) {
                const targetEndDate = moment(new Date())
                    .startOf('day')
                    .add(task?.days_to_complete, 'days')
                return moment(targetEndDate)
            } else {
                return moment(task?.due_date_end)
            }
        }
    }
    return (
        <div className='column-flex-5'>
            <div className="row-flex-5">
                {isShowIcon && <ClockIcon />}
                <span className="font-14-normal">{t('deadlines')}</span>
            </div>
            {type === TasksTabTypes.TASKS ? (
                <ConfigProvider locale={locale}>
                    <DatePicker.RangePicker
                        allowClear
                        format={DATE_FORMAT}
                        value={getDateValue()}
                        className="datePicker-item"
                        onChange={handleChangeRate}
                        disabledDate={disableDate}
                    />
                </ConfigProvider>
            ) : (
                <div
                    className={`task-details__date-block ${
                        i18n.language !== 'ru'
                            ? 'task-details__date-block-en'
                            : ''
                    }`}
                >
                    <div className="font-14-normal">
                        {t('from')} {getTodayDate()} -
                    </div>
                    <ConfigProvider locale={locale}>
                        <DatePicker
                            allowClear
                            format={DATE_FORMAT}
                            value={getDateValue()}
                            className="datePicker-item"
                            onChange={handleChangeRate}
                            disabledDate={disableDate}
                        />
                    </ConfigProvider>
                </div>
            )}
        </div>
    )
}

export default TaskDateBlock
