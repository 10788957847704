import {
    FC,
    default as React,
    default as react,
    useEffect,
    useLayoutEffect,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { TagsTypes } from '../TagsDropdown/types'
import TagsList from './TagsList'
import './TagsContainer.css'
import { getCurrentSystemTags } from './services/get-current-systemTags'
import { getInitialSystemTags } from './services/getInitialSystemTags'
import { ITagsListProps, SystemTagItem, SystemTagListItem } from './types'

export const TagsContainer: FC<ITagsListProps> = react.memo((props) => {
    const {
        currentTab,
        selectedTags,
        fetchedTags,

        addTags,
        deleteTags,
    } = props
    const { t, i18n } = useTranslation()
    const currentProject = useSelector(selectCurrentProject)
    const [cashList, setCashList] = useState<SystemTagListItem[]>([])

    const [tags, setTags] = useState<SystemTagItem[]>([])

    const handleClickOnBreadCrumb = (data: {
        tags: SystemTagItem[]
        cashList: SystemTagListItem[]
    }) => {
        setCashList(data.cashList)
        setTags(data.tags)
    }

    const handleSelectSystemItem = async (tagEl: SystemTagItem) => {
        const currentSystemTagsData = await getCurrentSystemTags(
            tagEl,
            currentProject?.id,
            t,
        )
        if (currentSystemTagsData?.cashListItem) {
            setCashList((prev) => [
                ...prev,
                currentSystemTagsData?.cashListItem,
            ])
        }
        if (currentSystemTagsData?.tags) {
            setTags(currentSystemTagsData?.tags)
        }
    }

    useEffect(() => {
        if (cashList.length === 0 && currentTab === TagsTypes.SYSTEM) {
            setCashList([
                {
                    list: getInitialSystemTags(t),
                    name: t('system'),
                },
            ])
            currentTab === TagsTypes.SYSTEM && setTags(getInitialSystemTags(t))
        }
    }, [i18n.language, currentTab])

    useEffect(() => {
        setTags(
            [TagsTypes.RESENT, TagsTypes.ORPHAN].includes(currentTab)
                ? fetchedTags
                : getInitialSystemTags(t),
        )
    }, [currentTab,fetchedTags])

    return (
        <TagsList
            cashList={cashList}
            tags={tags}
            selectedTags={selectedTags}
            addTags={addTags}
            deleteTags={deleteTags}
            handleClickBreadCrumbs={handleClickOnBreadCrumb}
            handleClickSystemFolder={handleSelectSystemItem}
        />
    )
})
