import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { setAppStatus } from '../app/actions'
import { projectsService } from '../../services/projects-service.'
import { organizationService } from '../../services/organization-service'
import { handleAsyncServerNetworkError } from '../../utils/helpers/errors/handleAsyncError'
import { IOrganization } from '../../app/types/models/i-organization'
import { AddOrganizationRequest } from '../../app/types/request'
import { projectsActions } from '../projects'
import { IOrganizationDTO } from '../../app/types/dto/organization.dto'

export interface IOrganizationState {
    organizations: IOrganization[]
}

const initialState: IOrganizationState = {
    organizations: [],
}

const fetchOrganizations = createAsyncThunk<
    IOrganization[],
    {
        onSuccess?: (res: IOrganization[]) => void
    },
    { rejectValue: string }
>('organization/fetchOrganizations', async ({ onSuccess }, thunkAPI) => {
    thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
    try {
        const res = await organizationService.getOrganizations()
        thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
        onSuccess && onSuccess(res)
        return res
    } catch (e: any) {
        return handleAsyncServerNetworkError(e, thunkAPI)
    }
})

const createOrganization = createAsyncThunk<
    IOrganizationDTO,
    { values: AddOrganizationRequest; onSuccess?: Function },
    { rejectValue: string }
>(
    'projects/createProjectAsync',
    async ({ values, onSuccess }, thunkAPI: any) => {
        try {
            thunkAPI.dispatch(setAppStatus({ status: 'loading' }))
            const organizationResponse =
                await organizationService.addNewOrganization(values)
            thunkAPI.dispatch(
                projectsActions.setCurrentOrganization(
                    organizationResponse?.id,
                ),
            )

            thunkAPI.dispatch(
                projectsActions.createProject({
                    values: {
                        name: 'Новый проект',
                        country: 'ru',
                        company_customer: 'default',
                        contractor: 'default',
                        organization: organizationResponse.id,
                        reservoir: 'default',
                    },
                }),
            )

            onSuccess && onSuccess(organizationResponse)
            thunkAPI.dispatch(setAppStatus({ status: 'succeeded' }))
            return organizationResponse
        } catch (e: any) {
            return handleAsyncServerNetworkError(e, thunkAPI)
        }
    },
)

export const asyncActions = { fetchOrganizations, createOrganization }

export const slice = createSlice({
    name: 'organization',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizations.fulfilled, (state, action) => {
            state.organizations = action.payload // Сохраняем полученные организации
        })
        builder.addCase(createOrganization.fulfilled, (state, action) => {
            if (action?.payload) {
                const newOrganization: IOrganization = {
                    id: action.payload.id,
                    address: action.payload?.address ?? '',
                    fullName: action.payload?.full_name ?? '',
                    shortName: action.payload?.full_name ?? '',
                }
                state.organizations = [...state.organizations, newOrganization]
            }
        })
    },
})
