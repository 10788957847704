import { AxiosResponse } from 'axios'
import { instance } from '../app/api'
import {
    IAddTemplateDTO
} from '../app/types/dto/templates.dto'
import { ITask } from '../app/types/models/task'

export const templatesService = {
    createTemplate: async (body: IAddTemplateDTO, id: number) => {
        return await instance
            .post<any, AxiosResponse<ITask>>(
                `projects/${id}/tasks/task/create-template/`,
                body,
            )
            .then((res) => res.data)
    },
}
