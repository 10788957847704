import { useSelector } from 'react-redux'
import { Link, Routes, useNavigate } from 'react-router-dom'
import { UserAvatar } from '../userAvatar/UserAvatar'
import React from 'react'
import { UserMenuSettingIcon } from '../../image_files/icons/UserMenuSettingIcon'
import { getLocalizedText } from '../../utils/helpers/getLocalizedText'
import { LogoutIcon } from '../../image_files/icons/LogoutIcon'
import { userSelectors } from '../../store/user'
import { ACCESS_TOKEN, routes } from '../../utils/constants'
import './profileMenu.css'
import { IUser } from '../../app/types/models/user'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { authActions } from '../../store/auth'

function ProfileMenu() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const user: IUser = useSelector(userSelectors.selectUser)
    const { t } = useTranslation()

    const logOutUser = () => {
        dispatch(authActions.logOut({
            onSuccess:()=>{
                navigate(routes.LOGIN)
            }
        }))
    }

    return (
        <div className="userPersonalWindow">
            <Link to={routes.LOGGING} className="userPersonalWindow__name">
                <UserAvatar />
                <p className={'userPersonalWindow__text'}>
                    <div>{`${user?.name ? user?.name : ''} ${
                        user?.surname ? user?.surname : ''
                    }`}</div>
                    <div>{user?.email}</div>
                </p>
            </Link>
            <Link to={routes.SETTINGS} className="userPersonalWindow__settings">
                <div>
                    <div>
                        <UserMenuSettingIcon />
                    </div>
                </div>
                <p>{t('settings')}</p>
            </Link>
            <div onClick={logOutUser} className="userPersonalWindow__logout">
                <LogoutIcon />
                <div>{getLocalizedText('log-out', t)}</div>
            </div>
        </div>
    )
}

export default ProfileMenu
