import { Form, Skeleton } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import moment, { Moment } from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DATE_FORMAT_TO_SERVER } from '../../../../app/constants'
import { CameraPlacementTypes } from '../../../../app/types/enums'
import { IFile } from '../../../../app/types/models/i-file'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import DefaultProfileAvatar from '../../../../image_files/icons/DefaultProfileAvatar'
import ChangePhotoMenu from '../../../../shared/changePhotoMenu/ChangePhotoMenu'
import UiButton from '../../../../shared/ui/button/UiButton'
import UiDatePicker from '../../../../shared/ui/datePicker/DatePicker'
import UiInput from '../../../../shared/ui/input/UiInput'
import { IUploadFile } from '../../../../shared/uploadButton/types'
import { userActions } from '../../../../store/user'
import {
    selectUser,
    selectUserFilesStatus,
    selectUserStatus,
} from '../../../../store/user/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../utils/helpers/showNotice'
import FileList from '../../../tasks/components/TaskFiles/TasksFiles'
import './ProfileTab.css'
import ProfileTabFormSkeleton from './ProfileTabFormSkeleton'
import imageCompression from 'browser-image-compression'
import UiMaskInput from '../../../../shared/ui/input/UiMaskInput'
import { getValidInitialDateFromServer } from '../../../../utils/helpers/dateHelpers'

interface ProfileTabProps {}

interface IFormValues {
    surname: string
    name: string
    patronymic: string
    date_of_birth: string | Moment
    mobile_phone: string
    work_phone: string
    email: string
    organization: string
    position: string
    personnel_number: string
    department: string
    room: string
    in_organization_since: string | Moment
    workplace: string
    experience: string
}

const ProfileTab = ({}: ProfileTabProps) => {
    const [form] = useForm<IFormValues>()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const usersStatus = useSelector(selectUserStatus)
    const usersFilesStatus = useSelector(selectUserFilesStatus)
    const userData = useSelector(selectUser)
    const [openModalTrigger, setOpenModalTrigger] = useState(0)

    const handleClickSaveBtn = () => {
        form.submit()
    }

    const saveFile = async (file: any) => {
        dispatch(
            userActions.addUserDocument({
                file,
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const deleteFile = async (id: number) => {
        dispatch(
            userActions.deleteUserDocument({
                id,
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const savePhoto = (data: IUploadFile) => {
        if (data?.view) {
            const formData = new FormData()
            imageCompression
                .getFilefromDataUrl(
                    data?.view,
                    'photo.jpg',
                    new Date().getTime(),
                )
                .then(async (res) => {
                    formData.append('avatar', new File([res], 'photo.jpg'))
                    formData.append('email', userData.email)
                    await updateUserData(formData, {
                        email: userData.email,
                    })
                })
                .catch((e) => {
                    errorsHandler(e, t)
                })
        }
    }

    const removePhoto = async () => {
        try {
            const formData = new FormData()
            formData.append('avatar', '')
            await updateUserData(formData, { email: userData.email })
        } catch (e) {
            errorsHandler(e, t)
        }
    }

    const updateUserData = async (
        data: FormData,
        emailData?: { email: string },
    ) => {
        dispatch(
            userActions.updateUser({
                data,
                onSuccess: (response) => {
                    showNotice('data-saved-successfully', t)
                    sessionStorage.removeItem('members')

                    if (emailData && emailData.email !== response.email) {
                        showNotice(
                            'confirmation-mail-has-been-sent',
                            t,
                            { className: 'changeEmailNotify' },
                            'info',
                            { email: emailData.email },
                        )
                    }
                },
            }),
        )
    }

    const onFinish = (values: IFormValues) => {
        const formData = new FormData()
        sessionStorage.removeItem('members')

        for (let field in values) {
            if (field !== 'email' && field !== 'receive_notifications') {
                if (
                    ['in_organization_since', 'date_of_birth'].includes(field)
                ) {
                    formData.append(
                        field,
                        values[field]
                            ? moment(values[field] ?? 0).format(
                                  DATE_FORMAT_TO_SERVER,
                              )
                            : '',
                    )
                } else {
                    formData.append(field, values[field])
                }
            }
        }

        formData.append(
            `${userData.email === values.email ? 'email' : 'new_email'}`,
            values.email,
        )
        updateUserData(formData, { email: values.email })
    }

    const setInitialValues = () => {
        if (userData) {
            const {
                name,
                patronymic,
                surname,
                company_name,
                position,
                about_myself,
                email,
                avatar,

                date_of_birth,
                department,
                experience,
                in_organization_since,
                mobile_phone,
                organization,
                personnel_number,
                room,
                work_phone,
                workplace,
            } = userData
            const values = {
                name: name ?? '',
                patronymic: patronymic ?? '',
                surname: surname ?? '',
                company_name: company_name ?? '',
                position: position ?? '',
                about_myself: about_myself ?? '',
                email: email ?? '',
                avatar,
                date_of_birth: getValidInitialDateFromServer(date_of_birth),
                department: department ?? '',
                experience: experience ?? '',
                in_organization_since: getValidInitialDateFromServer(
                    in_organization_since,
                ),
                mobile_phone: mobile_phone ?? '',
                organization: organization ?? '',
                personnel_number: personnel_number ?? '',
                room: room ?? '',
                work_phone: work_phone ?? '',
                workplace: workplace ?? '',
            }
            form.setFieldsValue(values)
        }
    }

    const showAvatar = () => {
        return (
            <div onClick={() => setOpenModalTrigger((prev) => prev + 1)}>
                {userData?.avatar?.medium ? (
                    <img src={userData?.avatar?.medium} alt="profile photo" />
                ) : (
                    <DefaultProfileAvatar />
                )}
            </div>
        )
    }

    useEffect(() => {
        dispatch(
            userActions.getUser({
                onSuccess: () => {
                    dispatch(userActions.getUserDocuments({}))
                },
            }),
        )
    }, [])

    useEffect(() => {
        if (userData) {
            setInitialValues()
        }
    }, [userData])

    return (
        <div className="profile">
            <div className="cursor-pointer profile__avatar">
                {usersStatus === 'loading' ? (
                    <Skeleton.Button
                        active
                        className="profile__avatar-skeleton"
                    />
                ) : (
                    showAvatar()
                )}
            </div>
            <Form
                className="profile__form"
                name="profile-form"
                form={form}
                onFinish={onFinish}
            >
                {usersStatus === 'loading' ? (
                    <ProfileTabFormSkeleton />
                ) : (
                    <div className="profile__form-fields">
                        <Form.Item name="surname">
                            <UiInput label={t('lastName')} />
                        </Form.Item>
                        <Form.Item name="name">
                            <UiInput label={t('firstName')} />
                        </Form.Item>
                        <Form.Item name="patronymic">
                            <UiInput label={t('middleName')} />
                        </Form.Item>

                        <Form.Item name="date_of_birth">
                            <UiDatePicker label={t('date-of-birth')} />
                        </Form.Item>
                        <Form.Item name="mobile_phone">
                            <UiMaskInput
                                label={t('mobile-phone')}
                                mask="+7 (999) 999-99-99"
                                placeholder="+7 (___) ___-__-__"
                            />
                        </Form.Item>
                        <Form.Item name="work_phone">
                            <UiMaskInput
                                label={t('work-phone')}
                                mask="+7 (999) 999-99-99"
                                placeholder="+7 (___) ___-__-__"
                            />
                        </Form.Item>
                        <Form.Item name="email">
                            <UiInput label={t('email-address')} />
                        </Form.Item>
                        <Form.Item name="organization">
                            <UiInput label={t('organization')} />
                        </Form.Item>
                        <Form.Item name="position">
                            <UiInput label={t('position')} />
                        </Form.Item>
                        <Form.Item name="personnel_number">
                            <UiInput
                                label={t('service-number')}
                                type="number"
                            />
                        </Form.Item>
                        <Form.Item name="department">
                            <UiInput label={t('department')} />
                        </Form.Item>
                        <Form.Item name="room">
                            <UiInput label={t('room')} />
                        </Form.Item>
                        <Form.Item name="in_organization_since">
                            <UiDatePicker label={t('in-organization-since')} />
                        </Form.Item>
                        <Form.Item name="workplace">
                            <UiInput label={t('work-place')} type="number" />
                        </Form.Item>
                        <Form.Item name="experience">
                            <UiInput
                                label={t('experience-with-years')}
                                type="number"
                            />
                        </Form.Item>
                    </div>
                )}
            </Form>
            <div className="profile__form-absent" />
            <div className="profile__form-files mt-8">
                <p className="font-14-normal field-wrap-label">
                    {t('profile-files')}
                </p>
                <FileList
                    files={userData?.files ?? []}
                    handleSave={saveFile}
                    handleDelete={deleteFile}
                />
            </div>
            <div className="profile__btn mt-8">
                <UiButton type="primary" onClick={handleClickSaveBtn}>
                    {t('save')}
                </UiButton>
            </div>
            <ChangePhotoMenu
                isDeleteMode={!!userData?.avatar?.medium}
                isCroopingMode
                openModalTrigger={openModalTrigger}
                cameraPlacement={CameraPlacementTypes.ENVIRONMENT}
                accept={['jpeg', 'png', 'jpg']}
                onRemove={removePhoto}
                onSave={savePhoto}
            />
        </div>
    )
}

export default ProfileTab
