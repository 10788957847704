import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ITask, ITasksFilterParams } from '../../app/types/models/task'
import TasksFilter from '../../features/tasks/components/TasksFilter/TasksFilter'
import { TasksTabTypes } from '../../features/tasks/types'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import { tasksActions } from '../../store/tasks'
import { selectTaskData, selectTaskStatus } from '../../store/tasks/selectors'
import { getValidText } from '../../utils/helpers/getValidText'
import TasksList from './TasksList'

const TasksContainer = ({
    type,
    className,
    getTasks,
    params,
}: {
    type: TasksTabTypes
    className?: string
    params: any
    getTasks: (params: ITasksFilterParams) => void
}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)
    const { results: tasks, count: tasksCount } = useSelector(selectTaskData)
    const tasksStatus = useSelector(selectTaskStatus)

    const [filterParams, setFilterParams] = useState<ITasksFilterParams>({
        ...params,
    })

    const [isHiddenContent, setIsHiddenContent] = useState(true)

    useEffect(() => {
        setIsHiddenContent(
                tasks.length === 0 &&
                (!filterParams?.name__icontains ||
                    (filterParams?.name__icontains &&
                        filterParams.name__icontains?.length === 0)),
        )
    }, [filterParams?.name__icontains, tasks.length])

    const getTask = (task: ITask) => {
        dispatch(
            tasksActions.getCurrentTask({
                slug: task?.slug,
                onSuccess: () => {
                    navigate(
                        `/scheduler/${currentOrganization}/${
                            currentProject?.id
                        }/tasks/${
                            type === TasksTabTypes.TASKS ? 'items' : 'templates'
                        }/${task?.slug}`,
                        {
                            state: {
                                slug: task?.slug,
                                type,
                            },
                        },
                    )
                },
            }),
        )
        // }
    }

    const handleChangePagination = async (params: {
        limit: number
        offset: number
    }) => {
        setFilterParams((prev) => ({ ...prev, ...params }))
    }

    useLayoutEffect(() => {
        if (currentProject?.id && currentOrganization !== undefined) {
            getTasks(filterParams)
        }
    }, [filterParams, currentProject?.id, currentOrganization])

    return (
        <div className={getValidText(className)}>
            {isHiddenContent ? (
                <div className="font-14-normal scheduler__absent-task-message">
                    {type === TasksTabTypes.TASKS
                        ? t('tasks-absent-message')
                        : t('templates-absent-message')}
                </div>
            ) : (
                <>
                    <div
                    >
                        <TasksFilter
                            filterParams={filterParams}
                            isDisabledSort={tasks?.length === 0}
                            setFilterParams={setFilterParams}
                        />
                        <TasksList
                            type={type}
                            getTask={getTask}
                            changePagination={handleChangePagination}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default TasksContainer
