import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import 'simplebar-react/dist/simplebar.min.css'
import { PriorityTypes } from '../../app/types/enums'
import TasksContainer from '../../entities/tasksContainer/TasksContainer'
import { useAppDispatch } from '../../hooks/redux-hooks'
import { CreatePanel } from '../../shared/CreatePanel/CreatePanel'
import { tasksActions } from '../../store/tasks'
import { TaskEditSpace } from './components/taskEditSpace/TaskEditSpace'

import { PlusIcon } from '../../image_files/icons/PlusIcon'
import { statusesServices } from '../../services/statuses-services'
import UiTabs from '../../shared/tabs/Tabs'
import { selectCurrentProject } from '../../store/currentProject/selectors'
import { selectCurrentOrganization } from '../../store/projects/selectors'
import './tasks.css'
import { TasksTabTypes } from './types'
import { getUrlChunks } from '../../utils/urlHelpers'
import { ITasksFilterParams } from '../../app/types/models/task'

const Tasks = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const urlChunks = getUrlChunks()
    const dispatch = useAppDispatch()
    const [currentTab, setCurrentTab] = useState<TasksTabTypes>(
        urlChunks[4] === 'items'
            ? TasksTabTypes.TASKS
            : TasksTabTypes.TEMPLATES,
    )
    const currentProject = useSelector(selectCurrentProject)
    const currentOrganization = useSelector(selectCurrentOrganization)

    const { setStatuses } = tasksActions

    //хэндлеры
    const handleAddTask = async (
        value: string,
        priorityStatus: PriorityTypes,
    ) => {
        const newTask = {
            name: value.trim(),
            priority: priorityStatus,
            is_template: currentTab === TasksTabTypes.TEMPLATES,
        }

        dispatch(
            tasksActions.createTask({
                projectId: currentProject?.id,
                task: newTask,
            }),
        )
    }

    const fetchTasks = (filterParams: ITasksFilterParams,onSuccess?:any) => {
        dispatch(
            tasksActions.getTasks({
                projectId: currentProject?.id,

                params: filterParams,
                onSuccess:onSuccess
            }),
        )
    }

    
    const handleChangeCurrentTab = (tab: string) => {
        setCurrentTab(tab as TasksTabTypes)
        if (tab === TasksTabTypes.TEMPLATES) {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/templates`,
            )
        } else {
            navigate(
                `/scheduler/${currentOrganization}/${currentProject?.id}/tasks/items`,
            )
        }
    }

    useLayoutEffect(() => {
        if (currentProject?.id !== undefined) {
            statusesServices
                .getStatuses(currentProject?.id)
                .then((res) => {
                    dispatch(setStatuses({ statuses: res }))
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [currentProject?.id])

    useLayoutEffect(() => {
        if (urlChunks[5]) {
            navigate('/' + urlChunks.join('/'), {
                state: { slug: decodeURI(urlChunks[5]) },
            })
        }
    }, [])

    const showEditSpace = () => {
        if (location.state?.slug) {
            return <TaskEditSpace />
        }
    }

    return (
        <div className="scheduler__tasks">
            <div className="pt-10">
                <div className="pos-relative">
                    <CreatePanel
                        addTask={handleAddTask}
                        prioritySelect={true}
                        className="scheduler__create-panel"
                        panelIcon={
                            <div className="font-14-normal row-flex-5">
                                <div className="add-entity-icon-wrap">
                                    <PlusIcon circle={false} />
                                </div>
                            </div>
                        }
                        placeholder={
                            currentTab === TasksTabTypes.TASKS
                                ? t('input-task-name')
                                : t('input-template-name')
                        }
                    />
                    <div className="scheduler__tabs row-flex-20">
                        <div
                        className={`tab-item ${currentTab===TasksTabTypes.TASKS ? 'tab-item--active' : ''}`}
                            onClick={() => {
                                handleChangeCurrentTab(TasksTabTypes.TASKS)
                            }}
                        >
                            {t('tasks')}
                        </div>
                        <div
                        className={`tab-item ${currentTab===TasksTabTypes.TEMPLATES ? 'tab-item--active' : ''}`}
                            onClick={() => {
                                handleChangeCurrentTab(TasksTabTypes.TEMPLATES)
                            }}
                        >
                            {t('templates')}
                        </div>
                    </div>
                    <TasksContainer
                        className="mt-10"
                        key={currentTab}
                        type={currentTab}
                        params={
                            {
                                        ordering: 'created_at',
                                        is_template: currentTab === TasksTabTypes.TEMPLATES,
                            }
                        }

                        getTasks={fetchTasks}
                    />
                </div>
            </div>
            {showEditSpace()}
        </div>
    )
}

export default Tasks
