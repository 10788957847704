import { Button, Form } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import { XIcon } from '../../../../image_files/icons/XIcon'
import CountrySelect from '../../../../shared/countrySelect/CountrySelect'
import UiInput from '../../../../shared/ui/input/UiInput'
import UISelect from '../../../../shared/ui/select/UlSelect'
import { getOrganizations } from '../../../../store/organization/selectors'
import { projectsActions, projectsSelectors } from '../../../../store/projects'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotice } from '../../../../utils/helpers/showNotice'
import './newProject.css'
import UiButton from '../../../../shared/ui/button/UiButton'

const CreateOrganozationProjectBlock = ({
    className,
    type = 'project',
    onSave,
}: {
    type?: 'organization' | 'project'
    className?: string
    onSave?: (data: any) => void
}) => {
    const { t, i18n } = useTranslation()
    const [isChangedField, setIsChangedField] = useState(false)
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const projects = useSelector(projectsSelectors.selectProjects)
    const currentOrganization = useSelector(
        projectsSelectors.selectCurrentOrganization,
    )
    const [selectData, setSelectData] = useState('RU')
    const organizations = useSelector(getOrganizations) ?? []

    const cancelHandler = (e) => {
        e.preventDefault()
        navigate(-1)
    }
    const onSelect = (value: string) => setSelectData(value)

    const showOrganizationFields = () => {
        return (
            <>
                <Form.Item
                    name="full_name"
                    label={t('full-name')}
                    rules={[
                        {
                            required: true,
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="short_name"
                    label={t('short-name')}
                    rules={[
                        {
                            required: true,
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="inn"
                    label={t('inn')}
                    rules={[
                        {
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="kpp"
                    label={t('kpp')}
                    rules={[
                        {
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="address"
                    label={t('address')}
                    rules={[
                        {
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                {onSave && <div></div>}
            </>
        )
    }

    const showProjectFields = () => {
        return (
            <>
                <Form.Item
                    name="name"
                    label={`${t('project-name')}`}
                    rules={[
                        {
                            required: true,
                            message: t('project-name-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="reservoir"
                    label={`${t('resorvior')}`}
                    rules={[
                        {
                            required: true,
                            message: t('resorvoir-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="organization"
                    label={`${t('organization')}`}
                    rules={[
                        {
                            required: true,
                            message: t('organization-required'),
                        },
                    ]}
                    className={'new-project__formItem'}
                >
                    <UISelect
                        options={organizations.map((el) => ({
                            label: el?.fullName,
                            value: el?.id,
                        }))}
                        onChange={() => setIsChangedField(true)}
                    />
                </Form.Item>
                <Form.Item
                    name="company_customer"
                    label={`${t('company-customer')}`}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="contractor"
                    label={`${t('assignee')}`}
                    className={'new-project__formItem'}
                >
                    <UiInput onChange={() => setIsChangedField(true)} />
                </Form.Item>
                <Form.Item
                    name="#"
                    label={`${t('object-country')}`}
                    className={'new-project__formItem'}
                >
                    <CountrySelect
                        onChange={onSelect}
                        selectedData={selectData}
                        setSelectedData={setSelectData}
                    />
                </Form.Item>
            </>
        )
    }

    const getValuesFromFormToRequest = (values: any) => {
        const nameValue = values?.name ? values.name.trim() : ""
        return {
            ...values,
            name: nameValue,
            country: selectData,
        }
    }

    //Form
    const onFormFinish = useCallback(
        async (values) => {
            try {
                await dispatch(
                    projectsActions.createProject({
                        values: getValuesFromFormToRequest(values),
                        onSuccess: (id: number) => {
                            navigate(`/logging/${id}`)
                            form.resetFields()
                            showNotice('new-project-added-successfully', t)
                        },
                    }),
                )
            } catch (error) {
                errorsHandler(error, t)
            }
        },
        [form, selectData, i18n],
    )

    useEffect(() => {
        if (
            form.getFieldsError(['name', 'reservoir']) &&
            form
                .getFieldsError(['name', 'reservoir'])
                .filter((item) => item.errors.length > 0).length > 0
        ) {
            form.submit()
        }
    }, [i18n.language])

    return (
        <div className={`new-project ${className}`}>
            <div className="new-project-container br-5">
                <Form
                    form={form}
                    onFinish={
                        onSave
                            ? (values) => {
                                onSave(getValuesFromFormToRequest(values))
                                form.resetFields()
                              }
                            : onFormFinish
                    }
                    initialValues={{
                        organization: currentOrganization,
                    }}
                    className={'new-project__form'}
                    layout="vertical"
                >
                    {type === 'project'
                        ? showProjectFields()
                        : showOrganizationFields()}
                    {onSave && <div />}
                    <Form.Item className='new-project__form-item'>
                        <UiButton
                            disabled={!isChangedField}
                            className=''
                            type='primary'
                            htmlType="submit"
                        >
                            {t(`create-${type}`)}
                        </UiButton>
                    </Form.Item>
                </Form>
                {!onSave && projects.length > 0 && (
                    <UiButton
                        className={'new-project__cancelBtn'}
                        onClick={cancelHandler}
                    >
                        <XIcon />
                    </UiButton>
                )}
            </div>
        </div>
    )
}

export { CreateOrganozationProjectBlock }

