import { IUser } from "../../app/types/models/user"

export const getFirstMemberCharacter = (data:any) => {
    let res = ''
        if(data){
            if(data?.user?.full_name && data?.user?.full_name?.length>0 ){
                res = data?.user?.full_name[0]
            }else{
                if(data?.user?.email){
                    
                    res =data?.user?.email[0]
                }else {
                   res = '123'
                }
            }
        }
    return res?.toUpperCase()
}

export const getFirstUserCharacter = (user:IUser) => {
    let res = ''
        if(user){
            if(user?.name){
                res = user?.name[0]
            }else if(user?.name){
                res = user?.patronymic[0] 
            }else if(user?.surname){
                res = user?.surname[0] 
            }else if(user?.email){
                res = user?.email[0]
            }else res = 'test'
        }
    return res?.toUpperCase()
}