export const routes = {
    LOGIN: '/login',
    REGISTRATION: '/register',
    PASSWORD_RECOVERY: '/recovery',
    RECOVERY_MESSAGE: '/recovery-message',
    RESET_CONFIRM: '/recover',
    RECOVERY_CHANGE: '/recovery-change',
    CHECK_EMAIL: '/check-email',
    CHANGE_EMAIL: '/change-email',
    EMAIL_CONFIRMATION: '/email-confirmation',
    VERIFY_GOOGLE: '/verify-google-account',
    VERIFY_SLACK: '/verify-slack-account',
    VERIFY_YANDEX: '/verify-yandex-account',
    VERIFY_MICROSOFT: '/verify-microsoft-account',
    HOME: '/*',
    LOGGING: '/logging/*',
    SCHEDULER: '/scheduler/*',
    SETTINGS: '/settings/*',
    SETTINGS_PROFILE: 'profile',
    SETTINGS_CHANGE_PASSWORD: 'change-password',
    SETTINGS_NOTIFICATIONS: 'notifications',
    EXTENDED_PROFILE: '/profile/*',
    NEW_PROJECT: '/logging/new-project',
    NEW_ORGANIZATION: '/logging/new-organization',
    FIRST_PROJECT: '/first-project',
    DYNAMIC_PROJECT: ':projectId',
    COPY_LINK: 'copy-link',
    GRAPHICS: 'graphics',
}

export const POROSITY = 'porosity'
export const PERMEABILITY = 'permeability'
export const SATURATION = 'saturation'
export const OIL_DISPLACEMENT = 'oilDisplasment'
export const CARBONATES = 'carbonateness'
export const DENSITY = 'density'
export const CAPILLARY_CURVE = 'capillaryCurve'
export const YOUNG_MODULE = 'youngModule'
export const POISSON_RATIO = 'poissonRatio'
export const ACOUSTIC_VELOCITY = 'acousticVelocity'
export const COMPRESSIBILITY = 'compressibility'
export const SHEAR_MODULUS = 'shearModulus'
export const YIELD_STRENGTH = 'yieldStrength'
export const BIO = 'bio'
export const BULK_MODULUS = 'bulkModulus'
export const COMPRESSIVE_STRENGTH = 'uniaxial compressive strength'
export const ULTIMATE_COMPRESSIVE_STRENGTH = 'ultimate compressive strength'
export const ELASTIC_LIMIT = 'Elastic limit'
export const CONDUCTIVITY = 'conductivity'
export const DIFFUSIVITY = 'diffusivity'
export const CAPACITY = 'capacity'
export const PYROLYSIS = 'pyrolysis'

export const ACCESS_TOKEN = 'token'
export const REFRESH_TOKEN = 'refresh_token'

export const WSS_BASE_URL_API = 'api.data-factory.ru/'
// export const WSS_BASE_URL_API = '51.250.113.104/'

export const HTTP_BASE_URL_API = `https://${WSS_BASE_URL_API}api/`


export const DEBOUNCE_DELAY = 500
