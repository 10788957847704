import { Skeleton } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useAppDispatch } from '../../../../hooks/redux-hooks'
import TaskNameEditBlock from '../../../../shared/taskNameEditBlock/TaskNameEditBlock'
import { UITextEditor } from '../../../../shared/text-editor/TextEditor'
import { UiAccordion } from '../../../../shared/ui/accordion/UiAccordion'
import UiButton from '../../../../shared/ui/button/UiButton'
import { selectStatus } from '../../../../store/app/selectors'
import { selectCurrentProject } from '../../../../store/currentProject/selectors'
import { tasksActions } from '../../../../store/tasks'
import { selectTaskData } from '../../../../store/tasks/selectors'
import { errorsHandler } from '../../../../utils/helpers/errors/errors-hendler'
import { showNotify } from '../../../../utils/helpers/showNotice'
import { TasksTabTypes } from '../../types'
import { Comment } from '../Comment/Comment'
import { SubTasks } from '../SubTasks/SubTasks'
import TaskDetails from '../TaskDetails/TaskDetails'
import FileList from '../TaskFiles/TasksFiles'
import './taskEditSpace.css'
import { ITask } from '../../../../app/types/models/task'
import TaskTextEditor from './TaskTextEditor'
import { getUrlChunks } from '../../../../utils/urlHelpers'
import moment from 'moment'
import { DATE_FORMAT_TO_SERVER } from '../../../../app/constants'

export const TaskEditSpace = () => {
    const { t } = useTranslation()
    const urlChunks = getUrlChunks()
    const dispatch = useAppDispatch()
    const currentProject = useSelector(selectCurrentProject)
    const { results } = useSelector(selectTaskData)
    const status = useSelector(selectStatus)
    const slug = decodeURI(urlChunks[5] ?? '')
    const type =
        urlChunks[4] === 'templates'
            ? TasksTabTypes.TEMPLATES
            : TasksTabTypes.TASKS
    const [task, setTask] = useState<ITask | null>(null)
    const [isLoading, setIsLoading] = useState(false)

    const fetchComments = async () => {
        dispatch(
            tasksActions.getTaskComments({
                slug,
                projectId: currentProject?.id,
                onError: (e) => {
                    errorsHandler(e, t)
                },
            }),
        )
    }

    const saveComment = async (text: string) => {
        dispatch(
            tasksActions.saveTaskComments({
                text,
                slug,
                projectId: currentProject?.id,
                onError: (e) => {
                    errorsHandler(e, t)
                },
            }),
        )
    }

    const addTaskFiles = async (file: any) => {
        dispatch(
            tasksActions.addFileToTask({
                file,
                projectId: currentProject?.id,
                slug,
                onSuccess: () => {
                    showNotify(t('file-added-successfully'))
                },
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const deleteTaskFile = async (fileId: number) => {
        dispatch(
            tasksActions.deleteTaskFile({
                fileId,
                slug,
                projectId: currentProject?.id,
                onSuccess: () => showNotify(t('file-deleted-successfully')),
                onError: (e) => errorsHandler(e, t),
            }),
        )
    }

    const updateDescription = (description: string) => {
        if (description !== task?.description) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { slug, description },
                    task: task,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    const updateName = (name: string) => {
        if (name !== task?.name) {
            dispatch(
                tasksActions.changeTaskFields({
                    data: { slug, id: task?.id, name },
                    task: task,
                    onError: (e) => errorsHandler(e, t),
                }),
            )
        }
    }

    const createTemplate = () => {
        const dateData = {}

        dateData['due_date_start'] = moment(new Date())
            .startOf('day')
            .format(DATE_FORMAT_TO_SERVER)

        if (task?.days_to_complete) {
            const targetEndDate = moment(new Date())
                .startOf('day')
                .add(task?.days_to_complete, 'days')
            dateData['due_date_end'] = moment(targetEndDate).format(
                DATE_FORMAT_TO_SERVER,
            )
        } else {
            dateData['due_date_end'] = dateData['due_date_start']
        }

        dispatch(
            tasksActions.createTask({
                projectId: currentProject?.id,
                task: {
                    name: task.name,
                    priority: task.priority,
                    status: task.status?.id,
                    description: task.description,
                    ...dateData,
                },

                onSuccess: () => {
                    showNotify(t('task-added-successfully'))
                    // handleChangePagination({ limit: 20, offset: 0 })
                },
            }),
        )
    }

    useEffect(() => {
        if (slug !== undefined && currentProject?.id) {
            fetchComments()
        }
    }, [task?.project, type])

    useEffect(() => {
        const targetTask = results.find((el) => el?.slug === slug)
        if (targetTask && type) {
            setTask(targetTask)
        }
    }, [slug, results])

    useEffect(() => {
        if (status === 'loading') {
            setIsLoading(true)
        } else if (status === 'succeeded' || status === 'failed') {
            setTimeout(() => {
                setIsLoading(false)
            }, 300)
        } else {
            setIsLoading(false)
        }
    }, [status])

    return (
        <div className={'scheduler__tasksSettings'}>
            <div className="p-10 br-default scheduler__container">
                <TaskNameEditBlock
                    slug={slug}
                    value={task?.name}
                    isLoading={isLoading}
                    onChange={updateName}
                    projectId={currentProject?.id}
                />
                {type === TasksTabTypes.TEMPLATES && (
                    <div className="mt-10">
                        <UiButton type="primary" onClick={createTemplate}>
                            + {t('create-task-by-template')}
                        </UiButton>
                    </div>
                )}
                <div className="mt-10">
                    <UiAccordion title={t('details')} activeStatus>
                        {task && (
                            <TaskDetails
                                isLoading={isLoading}
                                task={task}
                                type={type}
                            />
                        )}
                    </UiAccordion>
                    <TaskTextEditor
                        task={task}
                        isLoading={isLoading}
                        handleChange={updateDescription}
                    />
                    {task?.slug && task?.slug?.split('_').length < 3 && (
                        <UiAccordion
                            title={
                                type === TasksTabTypes.TEMPLATES
                                    ? t('subtemplates')
                                    : t('subtasks')
                            }
                            activeStatus
                        >
                            <SubTasks
                                task={task}
                                is_template={type === TasksTabTypes.TEMPLATES}
                            />
                        </UiAccordion>
                    )}
                    <UiAccordion title={t('pin-files')} activeStatus>
                        <FileList
                            files={task?.files}
                            handleSave={addTaskFiles}
                            handleDelete={deleteTaskFile}
                        />
                    </UiAccordion>

                    <UiAccordion activeStatus={true} title={t('comments')}>
                        <Comment
                            onSave={saveComment}
                            comments={task?.comments || []}
                        />
                    </UiAccordion>
                </div>
            </div>
        </div>
    )
}
