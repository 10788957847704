import { ACCESS_TOKEN } from '../utils/constants'
import {instance} from "../app/api";
import { AxiosResponse } from 'axios';
import { ITagResponse } from '../app/types/models/task';

export const tagsServices = {
    getTags: async (id:number,is_orphan?:boolean) => {
        return await instance.get<{id:number},AxiosResponse<ITagResponse>>(`projects/${id}/tasks/tag/`, {
            headers: {
                Authorization: `Bearer ${JSON.parse(
                    localStorage.getItem(ACCESS_TOKEN),
                )}`,
            },
            params: {
                limit: 9999999,
                is_orphan
            },
        }).then(res=>res.data)
    },
    createTag: async (projectId, data) => {
        const response = await instance.post(
            `projects/${projectId}/tasks/tag/`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
        return response.data
    },

    deleteTag: async (projectId, tagId) => {
        return await instance.delete(
            `projects/${projectId}/tasks/tag/${tagId}`,
            {
                headers: {
                    Authorization: `Bearer ${JSON.parse(
                        localStorage.getItem(ACCESS_TOKEN),
                    )}`,
                },
            },
        )
    },
}
