import React from 'react';
import {Settings} from "../../widgets/settings/Settings";
import {Outlet} from "react-router";
const SettingsPage = () => {
    return (
        <>
            <Settings/>
            <Outlet/>
        </>
    )
}

export default SettingsPage;