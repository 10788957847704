import React, { ChangeEvent } from 'react'
import { SystemTagItem } from './types'
import { getValidText } from '../../utils/helpers/getValidText'
import { useTranslation } from 'react-i18next'
import UiInput from '../ui/input/UiInput'

const MIN_TAGS_FILTER = 3

interface TagsFilterProps {
    tags: SystemTagItem[]
    className?:string
    onChange?:(e:ChangeEvent<HTMLInputElement>)=>void
}

const TagsFilter = ({ tags,className,onChange }: TagsFilterProps) => {
    const {t} = useTranslation()
    if (tags?.length === 0 || (tags?.length>0 && tags?.length<MIN_TAGS_FILTER)) {
        return <></>
    }

    return (
        <div className={`tags-search ${getValidText(className)}`}>
            <UiInput
                placeholder={t('search')}
                onChange={onChange}
            />
        </div>
    )
}

export default TagsFilter
