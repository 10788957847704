import { TFunction } from "i18next";
import { SystemTagItem } from "../types";
import { ProductObjectTypes } from '../../../app/types/enums';

export const getInitialSystemTags = (t:TFunction<"translation", undefined, "translation">): SystemTagItem[] => [
  {
      id: 1,
      type: 'folder',
      name:t('pad'),
      systemType: ProductObjectTypes.PAD,
  },
  {
      id: 2,
      type: 'folder',
      name:t('well'),
      systemType: ProductObjectTypes.WELL,
  },
  {
      id: 3,
      type: 'folder',
      name:t('wellbore'),
      systemType: ProductObjectTypes.WELLBORE,
  },
  {
      id: 4,
      type: 'folder',
      name:t('core'),
      systemType: ProductObjectTypes.CORE,
  },
  {
      id: 5,
      type: 'folder',
      name:t('method'),
      systemType: ProductObjectTypes.METHOD,
  },
  {
      id: 6,
      type: 'folder',
      name:t('equipment'),
      systemType: ProductObjectTypes.EQUIPMENT,
  },
//   {
//       id: 7,
//       type: 'folder',
//       name:t('studies'),
//       systemType: ProductObjectTypes.STUDIES,
//   },
]