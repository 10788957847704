import { Input, InputProps } from 'antd'
import React, { ReactNode } from 'react'
import InputMask from 'react-input-mask'
import { getValidText } from '../../../utils/helpers/getValidText'
import './UiInput.css'

type IProps = InputProps & {
    label?: string | ReactNode
    ref?: any
    mask?: string
}

const UiMaskInput = (props: IProps) => {
    return (
        <div className="field-wrap">
            {props?.label && (
                <div className="font-14-normal field-wrap-label">
                    {props?.label}
                </div>
            )}

            <InputMask
                value={props?.value}
                mask={props?.mask}
                maskChar=""
                className="input-mask"
                autoFocus={false}
                disabled={props?.disabled}
                inputMode="numeric"
                pattern={'[0-9]'}
                alwaysShowMask={false}
                //   className={`black-white-color input-mask card-number-field ${errorText.length > 0 ? 'card-number-field-error' : ''}`}
                onChange={props?.onChange}
                onBlur={props?.onBlur}
            />
        </div>
    )
}

export default UiMaskInput
